import { Stage } from 'konva/lib/Stage';
import { 
	MAX_ZOOM_LIMIT, 
	MIN_ZOOM_LIMIT, 
	PANEL_ENERGY_COLORS, 
	IRRADIANCE_UPRATE_RATE,
	FACET_HIGHLIGHT_OFF_COLOR,
	SEMI_TRANPARENT_BLACK_PANEL, 
	FULL_TRANPARENT_BLACK_PANEL
} from '../../../constants';
import { getDeratedValue, getPercentage } from '../../../util';
import Konva from 'konva';
import { KonvaGroupNames } from './konvaUtil';
import { pointInPolygon, Polygon, Vector } from 'sat';

function transformLatLngToRasterPoints(latitude: Lat, longitude: Lng, transformData: {
	boundingBox: BoundingBox,
	image: {
		width: number;
		height: number;
	}
}): {
	x: Lng,
	y: Lat
} {

	// boundingBox [minLong, minLat, maxLong, maxLat];
	const { sw, ne } = transformData.boundingBox;
	const { width: imageWidth, height: imageHeight } = transformData.image;

	const bboxWidth = ne.longitude - sw.longitude;
	const bboxHeight = ne.latitude - sw.latitude;
	const widthPerPixel = (longitude - sw.longitude) / bboxWidth;
	const heightPerPixel = (latitude - sw.latitude) / bboxHeight;

	const x = imageWidth * widthPerPixel;
	const y = imageHeight * (1 - heightPerPixel);
	// console.log('center',x,y);
	return {
		x, y
	};
}

function degToRad(angle: number) {
	return angle / 180 * Math.PI;
}

function getCenter(topLeft: Pos, dim: Dimensions) {
	const angleRad = degToRad(0);
	return {
		x:
			topLeft.x +
			dim.width / 2 * Math.cos(angleRad) +
			dim.height / 2 * Math.sin(-angleRad),
		y:
			topLeft.y +
			dim.height / 2 * Math.cos(angleRad) +
			dim.width / 2 * Math.sin(angleRad)
	};
}

function rotateAroundPoint(topLeft: Pos, dim: Dimensions, deltaDeg: number, point: Pos) {
	const angleRad = degToRad(deltaDeg);
	const x = Math.round(
		point.x +
		(topLeft.x - point.x) * Math.cos(angleRad) -
		(topLeft.y - point.y) * Math.sin(angleRad)
	);
	const y = Math.round(
		point.y +
		(topLeft.x - point.x) * Math.sin(angleRad) +
		(topLeft.y - point.y) * Math.cos(angleRad)
	);
	return {
		x,
		y
	};
}

export function rotateAroundCenter(topLeft: Pos, dim: Dimensions, deltaDeg: number) {
	const center = getCenter(topLeft, dim);
	return rotateAroundPoint(topLeft, dim, deltaDeg, center);
}

export function getSolarEnergyDetails(panels: RasterSolarPanel[], panelCapacityWatts: number, selectedPanels: string[], energyDerateRate: number) {
	let totalEnergy = 0, totalIrradiance = 0;
	for (let i = 0; i < panels.length; i++) {
		if (!selectedPanels.includes(panels[i].id)) continue;
		totalEnergy += panels[i].yearlyEnergyDcKwh;
		totalIrradiance += panels[i].irradiance;
	}
	// const offset = Math.round((energy.energy/energy.billEnergy)*100);
	return {
		energy: totalEnergy,
		deratedEnergy: getDeratedValue(totalEnergy, energyDerateRate),
		totalNoOfPanelsSelectedByUser: selectedPanels.length,
		panelCapacityWatts,
		selectedPanels,
		deratedBy: energyDerateRate,
		irradiance: totalIrradiance,
		upratedIrradiance: totalIrradiance + getPercentage(totalIrradiance, IRRADIANCE_UPRATE_RATE),
	};
}

export function getOffsetIndex(panels: RasterSolarPanel[], billEnergy: number, energyDerateRate: number) {
	let offset, totalEnergy = 0;
	let offset120;
	let offset150;
	for (let i = 0; i < panels.length; i++) {
		// totalEnergy += panels[i].yearlyEnergyDcKwh;
		totalEnergy += getDeratedValue(panels[i].yearlyEnergyDcKwh, energyDerateRate);
		offset = Math.round((totalEnergy / billEnergy) * 100);
		if (offset >= 120 && !offset120) {
			offset120 = i;
		}
		if (offset >= 150 && !offset150) {
			offset150 = i;
		}
	}

	return ({
		offset120,
		offset150
	});
}

export function detectPanelColor(energyValue: number) {
	// color-corrdinate: 500-425 red, 424-375 orange, 375-300 yellow,
	// panels under 300 remove them
	// && energyValue <= 500
	if (energyValue > 678) {
		return PANEL_ENERGY_COLORS.HIGH;
	}
	if (energyValue > 600 && energyValue <= 678) {
		return PANEL_ENERGY_COLORS.MEDIUM;
	}

	return PANEL_ENERGY_COLORS.LOW;

}

export function getAllKonvaGroups(konvaRef: Stage) {
	return konvaRef.find('Group').filter(n => !(n instanceof Konva.Transformer)) as Konva.Group[];
}

export function disableDraggingInKonvaGroups(groups: Konva.Group[]) {
	groups?.forEach(g => {
		g.draggable(false);
	});
}

export function deleteAllFacetsFromPanelDrawer(konvaRef: Konva.Stage) {
	getAllKonvaGroups(konvaRef)?.forEach((g => {
		if (g.attrs['name'] === 'panel-drawer') {
			[...(g?.children || [])].forEach((c) => {
				if (c instanceof Konva.Image) return;
				c.remove();
			});
		}
	}));
	konvaRef.batchDraw();
}

export function getPanelDrawerGroup(konvaRef: Konva.Stage) {
	return getAllKonvaGroups(konvaRef).filter(g => g.hasName('panel-drawer'))[0];
}

export function checkPanelDrawerHasUnfinalizedFacets(konvaRef: Konva.Stage) {
	const panelDrawerGroup = getAllKonvaGroups(konvaRef).filter(g => g.hasName('panel-drawer'))[0];
	return !!panelDrawerGroup?.children && panelDrawerGroup.children.length > 1;
}

export function getKonvaGroupByName(konvaRef: Konva.Stage, name: string) {
	return konvaRef.find('Group').filter(node => node.hasName(name))[0] as Konva.Group;
}

export function normalizeAngle(angle: number) {
	return (angle % 360 + 360) % 360;
}

export function zoomAtAPosition(stage: Konva.Stage, postion: Vector2d, type: 'in' | 'out', by?: number) {
	const stageScale = stage.scaleX();
	const scaleBy = by || 0.1;
	const newScale = type === 'out' ? stageScale - scaleBy : stageScale + scaleBy;

	if (newScale < MIN_ZOOM_LIMIT || newScale > MAX_ZOOM_LIMIT) return;

	const mousePointTo = {
		x: (postion.x - stage.x()) / stageScale,
		y: (postion.y - stage.y()) / stageScale,
	};
	const newPos = {
		x: postion.x - mousePointTo.x * newScale,
		y: postion.y - mousePointTo.y * newScale,
	};

	if (Math.abs(newPos.x) > stage.width() || Math.abs(newPos.y) > stage.height()) return;
	stage.position(newPos);
	return { scaleBy: newScale, position: newPos };
}

export function setZoomLevelOnStageFromCenter(stage: Konva.Stage) {
	const newPos= getNewPositonByApplyiingScaleFromCenter(stage);
	stage.position(newPos);

}

export function applyDisplacementBound(stage: Konva.Stage, pos: Vector2d){

	const scaleX = stage.scaleX();
	const scaleY = stage.scaleY();
	const raster= getPanelDrawerGroup(stage)?.find('Image')[0] as Konva.Image;
	const stageWidth = raster.width() * scaleX;
	const stageHeight = raster.height() * scaleY;

	const minDisplacementBound = {
		x: -stageWidth / 2,
		y: -stageHeight / 2
	};

	const maxDisplacementBound = {
		x: stage.width() + minDisplacementBound.x,
		y: stage.height() + minDisplacementBound.y
	};

	pos.x = Math.min(Math.max(pos.x, minDisplacementBound.x), maxDisplacementBound.x);
	pos.y = Math.min(Math.max(pos.y, minDisplacementBound.y), maxDisplacementBound.y);

	return pos;
}

export function resizePoints(points: number[], scale: Vector2d){
	return points.map((point, index) => index % 2 === 0 ? point * scale.x : point * scale.y);
}

export function getNewPositonByApplyiingScaleFromCenter(stage: Konva.Stage, konvaZoomScale?:number) {
	const stageScale = konvaZoomScale ?? stage.scaleX();
	const center = {
		x: stage.width() / 2,
		y: stage.height() / 2
	};

	const newPos = {
		x: center.x - center.x * stageScale,
		y: center.y - center.y * stageScale,
	};
	return newPos;
}

/**
 * 
 * @param polygon 
 * @returns perpendicular angle
 */
export function calculateAzimuthForNewlyCreatedFacet(polygon: number[][]) {
	const [x1, y1] = polygon[0];
	const [x2, y2] = polygon[1];

	const { x: Cx, y: Cy } = calculateCentroidOfPolygon(polygon.flat());

	const Mx = (x1 + x2) / 2, My = (y1 + y2) / 2; //midpoint;

	const theta = Math.atan2(y2 - y1, x2 - x1);

	const perpAngle1 = (theta + Math.PI / 2) * (180 / Math.PI);
	const perpAngle2 = (theta - Math.PI / 2) * (180 / Math.PI);

	// vector from the midpt to the centroid;
	const dx = Cx - Mx, dy = Cy - My;

	// Choose the perpendicular angle where the dot product is negative
	const dotProduct = dx * Math.cos(perpAngle1) + dy * Math.sin(perpAngle1);

	let angleDegrees = dotProduct < 0 ? perpAngle1 : perpAngle2;
	angleDegrees = (angleDegrees + 360) % 360;

	// angle with respect to the y-axis
	const finalAngle = (90 + angleDegrees) % 360;
	return finalAngle;
}

export function calculateArrowPoints(points: number[][], centroid: Vector2d) {

	const x1 = points[0][0];
	const y1 = points[0][1];
	const x2 = points[1][0];
	const y2 = points[1][1];

	// Calculate the midpoint of the side
	const midX = (x1 + x2) / 2;
	const midY = (y1 + y2) / 2;

	const theta = Math.atan2(y2 - y1, x2 - x1);

	const perpAngle1 = (theta + Math.PI / 2) * (180 / Math.PI);
	const perpAngle2 = (theta - Math.PI / 2) * (180 / Math.PI);

	// vector from the midpt to the centroid;

	// Calculate the direction vector of the side
	const dirX = x2 - x1;
	const dirY = y2 - y1;

	// Compute the outward normal vector (rotate the direction vector by 90 degrees)
	let outwardDirX = -dirY;
	let outwardDirY = dirX;

	// Normalize the normal vector
	const length = Math.sqrt(
		outwardDirX * outwardDirX + outwardDirY * outwardDirY
	);
	outwardDirX /= length;
	outwardDirY /= length;

	// Check if the normal is pointing outward using the dot product
	const vectorToCentroidX = centroid.x - midX;
	const vectorToCentroidY = centroid.y - midY;
	const dotProduct =
		outwardDirX * vectorToCentroidX + outwardDirY * vectorToCentroidY;

	let outwardAngle = perpAngle1, invardAngle = perpAngle1, invardDirX, invardDirY;
	// If the dot product is positive, the arrow is pointing inward, so reverse the direction
	if (dotProduct > 0) {
		invardDirX = outwardDirX;
		invardDirY = outwardDirY;
		outwardDirX = -outwardDirX;
		outwardDirY = -outwardDirY;
		outwardAngle = perpAngle2;
		invardAngle = perpAngle1;
	} else {
		invardDirX = -outwardDirX;
		invardDirY = -outwardDirY;
		outwardAngle = perpAngle1;
		invardAngle = perpAngle2;
	}

	// Scale the outward vector for the arrow size
	const arrowLength = 20, arrowStartPointOffset = 6;
	const padding = 3;

	const rectHeight = arrowLength + 2 * padding;
	const rectWidth = rectHeight;
	const rectCenterX = midX + outwardDirX * rectHeight / 2;
	const rectCenterY = midY + outwardDirY * rectHeight / 2;
	const rectAngle = Math.atan2(outwardDirY, outwardDirX) * (180 / Math.PI);

	const outwardArrowEndX = midX + outwardDirX * arrowLength;
	const outwardArrowEndY = midY + outwardDirY * arrowLength;
	const outwardArrowStartX = midX + outwardDirX * arrowStartPointOffset;
	const outwardArrowStartY = midY + outwardDirY * arrowStartPointOffset;

	const invardArrowEndX = midX + invardDirX * arrowLength;
	const invardArrowEndY = midY + invardDirY * arrowLength;

	outwardAngle = (outwardAngle + 360) % 360;
	invardAngle = (invardAngle + 360) % 360;

	//doubt: by this adjustments arrows are not aligning to the normal vector
	// const pp = 6;
	// if (outwardArrowEndY > midY) {
	// 	// midX -= 15
	// 	midY += pp;
	// } else {
	// 	// midX += 15;
	// 	midY -= pp;
	// }

	// return [[midX, midY], [arrowEndX, arrowEndY], (90 + angle) % 360];

	return {
		rectangle: {
			x: rectCenterX,
			y: rectCenterY,
			width: rectWidth,
			height: rectHeight,
			rotation: rectAngle,
			offsetX: rectWidth / 2,
			offsetY: rectHeight / 2
		},
		arrow:{
			'invardPoints': [[midX, midY], [invardArrowEndX, invardArrowEndY]],
			'outwardPoints': [[outwardArrowStartX, outwardArrowStartY], [outwardArrowEndX, outwardArrowEndY]],
			'angle': (90 + outwardAngle) % 360
		}
	
	};
}

export function calculateCentroidOfPolygon(points: number[]) {
	// Ensure that the first point is the same as the last point
	if (points[0] !== points[points.length - 2] || points[1] !== points[points.length - 1]) {
		points.push(points[0], points[1]); // Closing the polygon loop
	}

	const n = points.length / 2; // Number of points
	let area = 0;
	let centerX = 0;
	let centerY = 0;

	for (let i = 0; i < n - 1; i++) {
		const x0 = points[2 * i];
		const y0 = points[2 * i + 1];
		const x1 = points[2 * (i + 1)];
		const y1 = points[2 * (i + 1) + 1];

		const crossProduct = x0 * y1 - x1 * y0;

		area += crossProduct;
		centerX += (x0 + x1) * crossProduct;
		centerY += (y0 + y1) * crossProduct;
	}

	area *= 0.5;
	centerX *= (1 / (6 * area));
	centerY *= (1 / (6 * area));

	return { x: centerX, y: centerY };
}
const rotatePoint = (x: number, y:number, angle:number, cx:number, cy:number) => {
	const radians = (angle * Math.PI) / 180;
	const cos = Math.cos(radians);
	const sin = Math.sin(radians);
	return [
		cos * (x - cx) - sin * (y - cy) + cx,
		sin * (x - cx) + cos * (y - cy) + cy
	];
};

export function convertLineCoordsToRectConfig(lineCoords: number[][], scale: Vector2d, angle:number): Partial<Konva.RectConfig>{

	const coords = lineCoords.slice(0,4);
	const xs = coords.map(point => point[0]);
	const ys = coords.map(point => point[1]);

	const cx = xs.reduce((sum, x)=>x+sum, 0)/4;
	const cy = ys.reduce((sum, y)=>y+sum, 0)/4;

	const rotatedCoords = coords.map(([x, y]) => rotatePoint(x, y, -angle, cx, cy));

	const rotatedxs = rotatedCoords.map(point => point[0]);
	const rotatedys = rotatedCoords.map(point => point[1]);

	const minX = Math.min(...rotatedxs);
	const maxX = Math.max(...rotatedxs);
	const minY = Math.min(...rotatedys);
	const maxY = Math.max(...rotatedys);

	const width = maxX - minX;
	const height = maxY - minY;

	const x = minX * scale.x + width / 2;
	const y = minY * scale.y + height / 2;

	return {x,y,width, height, scale, offset: {x: width/2,y: height/2}, rotation: angle};
}

/**
 * reset all tranformations got from transalation and while seleting panelGroup to align the handle to its azimuth
 * reset hightlight
 * @param stage 
 */
export function resetAllTransformations(stage: Konva.Stage) {
	
	const allFacetGroups = stage.find(`.${KonvaGroupNames.existingFacetsGroup}`) as Konva.Group[];

	// //detach transformer
	const tranformer = stage.findOne('Transformer') as Konva.Transformer;
	if(tranformer?.getNodes()?.length) tranformer.setNodes([]);

	for (const facetGrp of allFacetGroups) {
		if (!facetGrp.children?.length) continue;

		for (const child of facetGrp.children) {
			const { rotation, fill } = child.attrs;
			const isFacetPolygon = child instanceof Konva.Line;
			const isPanelGroup = child instanceof Konva.Group;
			if (isFacetPolygon && fill !== FACET_HIGHLIGHT_OFF_COLOR) {
				child.fill(FACET_HIGHLIGHT_OFF_COLOR);
			} else if (isPanelGroup && !(rotation == null)) {
				// panel group trandformations
				child.draggable(false);
				child.rotation(0);
				child.position({ x: 0, y: 0 });
				delete child.attrs.rotation;

				//individual panel polygon
				const panels = child.getChildren() as Konva.Line[];
				for (const line of panels) {
					if (line.attrs.rotation) line.rotation(0);
					if (line.attrs.fill === FULL_TRANPARENT_BLACK_PANEL) line.fill(SEMI_TRANPARENT_BLACK_PANEL);
				}
			}
		}
	}
}

export default transformLatLngToRasterPoints;
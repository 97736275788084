import styled from 'styled-components';

export const StripDiv = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 0;
	padding-left:.15rem;

	&.azimuthStrip{
			color: #2E384D; 
			line-height: 1.5; 
			fontSize: 1rem; 
			gap: 6px;
			cursor: pointer; 
	}
	label{
		font-size: 0.813rem;
		color: #4A525A;
		transition-delay: 1s;
		font-family: Inter;
		position:relative;
	}
`;
export const LabelInfo = styled.div`
		font-size: 0.813rem;
		color: #4A525A;
		transition-delay: 1s;
		font-family: Inter;
		font-weight:600;
		position:relative;
		padding-right: .15rem;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: .5rem;
		min-height:27px;
		border-right: 1px solid #e4e4e4;
		padding-right: .65rem;

`;
export const StripDivEdit = styled.div`
 display: flex;
  flex-wrap: nowrap;
	align-items: center;
	min-height: 31px;

	input{
			border: 1px solid;
			width: 52px;
			color: #223349;
			padding-block: 3px;
			padding-left: 5px;
			padding-right: 3px;
			margin-left: .35rem;
			border-radius: .2rem;
			fontSize: .938rem;
			outline: none;
			transition: border-color 0.3s ease-in-out;
	}
		
	#azimuth-alert{
		font-size: .75rem;
		border: none;
		display: flex;
		margin-left: 5px;
		margin-right: 5px;
		align-items: center;
		justify-content: center;
		color: #c53030;
		background: rgba(255,245,245,1);
		padding: 0;
		min-height: auto;
		min-width: 19px;
		padding-left:1px;

	}
`;
export const InvalidAzimuthWrap = styled.div`
			color: #c53030;
			display: flex;
  		flex-wrap: nowrap;
			align-items: center;
			margin-left: .5rem;
			span{
				font-size: .75rem;
				color: #c53030;
			}
`;

import styled from 'styled-components';

export const NextStepperDivDesc = styled.div`
color:#7C7C7C;
  font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.2em;
`;

export const NextStepperDivTitleList = styled.div`
color:#7C7C7C;
  font-size: 0.825rem;
    font-weight: 500;
`;
export const NextStepperDivTitle = styled.div`
color:#073265;
  font-size: 0.875rem;
    font-weight: 700;
    margin-bottom:6px;
    line-height: 1.4em;
`;
export const NextStepperDiv = styled.div`
background: linear-gradient(to bottom, #fcfdff 0%,#eff7ff 66%,#e7f3ff 100%);
  
.stepperCount{
  color:#FF932B;
  font-size: 1rem;
    font-weight: 700;
}

@media (max-width: 935px){
	display:none;
}

.timeline {
  border-left: 1px solid #C9C9C9;
   
    padding-left: 1em;
    list-style: none;
    text-align: left;
    margin-left: 2em;
    padding-top: 1.75rem;
    padding-bottom: 2.5rem;
    margin-top: 0px;
    padding-right: 2rem;
		@media (min-width: 1023px) and (max-width: 1399px) and (max-height: 900px) {
 padding-top: 1rem;
  padding-bottom: 1rem;
	margin-bottom:0px
}
}

.event {
  width: 100%;
  vertical-align: middle;
  box-sizing: border-box;
  position: relative;
}

.timeline .event:before,
.timeline .event:after {
  position: absolute;
  display: block;
  top: 1em;
}

.timeline .event:after {
  box-shadow: 0 0 0 0.2em #9D9FB1;
  left: -1.4em;
  background: #fff;
  border-radius: 50%;
  height: 0.675em;
  width: 0.675em;
  content: "";
}

.member-infos {
  padding: 8px 10px;
  text-align: left;
  position: relative;
}
`;
import Button from 'components/Basic/Button';
import { checkPanelDrawerHasUnfinalizedFacets, deleteAllFacetsFromPanelDrawer } from 'components/tool/utils';
import { useRefs } from 'contexts/RefContext';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { toggleResetTranslationsModalState } from 'store/slices/EditFacetModalSlice';
import { setAddPanel, setDeleteIndividualPanelsMode, setPanelMove, togglePanelModal } from 'store/slices/PanelSlice';
import { setEnableFacetEdit } from 'store/slices/RoofDataSlice/roofDataSlice';
import { setDrawModeState, showDeleteObstructionsPopUp, toggleDeleteObstructionsMode } from 'store/slices/ToolSlice';

const MutationKeys= [
	'create-new-facets', 'add-panels', 'remove-panels', 'move-panels', 'translate-panels', 'delete-obstructions'
];

export default function CancelBtn(props: {strip?:boolean}) {
	const { enableAddPanel, deleteIndividualPanelMode, newlyAddedPanels,
		deletedPanels, enablePanelMove, currentMovablePanels } = useSelector((state: RootState) => state.panelSlice.data);
	const { drawModeEnabled, deleteObstructionsMode, deletedObstructionIDs } = useSelector((state: RootState) => state.toolNewPostions.data);
	const { enableEditFacet } = useSelector((state: RootState) => state.roofData.data);
	const showCancelModalIfPanelsHaveModification = !!newlyAddedPanels.length || !!deletedPanels.length || !!currentMovablePanels.length;
	const disable = useQueriesRunningInBackGround( MutationKeys, 'mutating');
	const dispatch = useDispatch();
	const {konvaRef} = useRefs();

	function getTargetModal() {
		if (enableAddPanel) return 'CANCEL_ADD_PANEL';
		if (deleteIndividualPanelMode) return 'CANCEL_DELETE_PANEL';
		if (enablePanelMove) return 'CANCEL_MOVE_PANEL';
		return '';
	}

	function handleClick() {
		if (showCancelModalIfPanelsHaveModification) {
			const targetModal = getTargetModal();
			dispatch(togglePanelModal({ targetModal, val: true }));
			return;
		}

		if(drawModeEnabled) {
			if (drawModeEnabled && konvaRef?.current && checkPanelDrawerHasUnfinalizedFacets(konvaRef.current)) {
				deleteAllFacetsFromPanelDrawer(konvaRef.current);
			}
			dispatch(setDrawModeState({ enabled: false }));
		} else if(deleteObstructionsMode) {
			if(deletedObstructionIDs.length > 0) {
				dispatch(showDeleteObstructionsPopUp({ enable: true }));
			} else dispatch(toggleDeleteObstructionsMode({ enable: false }));
		} else if (enableAddPanel) {
			dispatch(setAddPanel({ shouldEnable: false }));
		} else if (deleteIndividualPanelMode) {
			dispatch(setDeleteIndividualPanelsMode({ enabled: false }));
		} else if (enablePanelMove) {
			dispatch(setPanelMove({ shouldEnablePanelMove: false }));
		} else if (enableEditFacet) {
			dispatch(setEnableFacetEdit({isEnable: false}));
		}	else {
			dispatch(toggleResetTranslationsModalState({ value: true }));
		}
	}
	return (
		<Button
			onClick={handleClick}
			disabled={disable}
			className={`${props.strip? 'strip-cancel' : ''} cancel-btn`}
		>
			<svg width="15" height="15" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path id="cancel-path" d="M0.947266 0.947266L14.2631 14.2631M14.2631 0.947266L0.947265 14.2631" stroke={`${props.strip? '#989898' : '#64686A'}`} strokeWidth="1.6" strokeLinecap="round"/>
			</svg>

		</Button>
	);

}
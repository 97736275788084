import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { Group } from 'react-konva';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';

import { SEMI_TRANPARENT_BLACK_PANEL, FULL_TRANPARENT_BLACK_PANEL } from '../../../constants';
import { getExistingFacetsGroupID, getRoofSegmentIDFromGroupID, KonvaGroupNames, resetAllTransformations } from '../utils';

import { setRoofIndexes, setSelectedRoofAzimuth, setSelectedRoofIndex, updateTransition } from 'store/slices/ToolSlice';
import { useRefs } from 'contexts/RefContext';

import Facet from '../Facet';
import PanelsGroup from './PanelsGroup';
import { setDropdownState } from 'store/slices/EditFacetModalSlice';
import TransformerComponent from './PanelsGroup/Transformer';
import EditableFacet from '../Facet/EditableFacet';
import { setEditableFacets } from 'store/slices/RoofDataSlice/roofDataSlice';
import { useLongPress } from 'hooks/useLongPress';

function FacetGroup() {
	const { roofSegs, allRoofSegs, enableEditFacet} = useSelector((state: RootState) => state.roofData.data);
	const editRoofState = useSelector((state: RootState) => state.toolNewPostions.data);
	const { roofIndexes, editModeEnabled, selectedRoofIndex, deletedRoofIndexes, moveStage, 
		currentActiveFacetEditMode, threeDModelEnabled, azimuthEditedFacets } = editRoofState;
	const { showIrradiance, showPanels } = useSelector((state: RootState) => state.Irradiance.data);
	const isIndividualPanelModeActive = useSelector((state: RootState) => state.panelSlice.data.activePanelMode);
	const listenToEventsOnGroup = editModeEnabled && !(isIndividualPanelModeActive || enableEditFacet);
	
	const trRef = useRef<Konva.Transformer>(null);
	const currentSelectedFacetGroup = useRef<Konva.Group>();
	const longTouchTimerRef = useRef<NodeJS.Timeout | undefined>(undefined);
	const dispatch = useDispatch<AppDispatch>();
	const { konvaRef } = useRefs();
	const listening = !moveStage && !showIrradiance && !currentActiveFacetEditMode;

	const { handleTouchStart, handleTouchEndOrCancel } = useLongPress({
		delay: 200,  // ms
		enabled:  listening && !(enableEditFacet || isIndividualPanelModeActive),
		onLongPress: openEditFacetPopup,
	});

	const hideFacetGrpInIrradianceMode = (showIrradiance && !showPanels);
	const selectedRoofSegment = useMemo(() => (allRoofSegs.find(r => r.id === selectedRoofIndex)), [selectedRoofIndex, allRoofSegs]);

	function addRoofIndex() {
		const roofIndex = getRoofSegmentIDFromGroupID(trRef.current?.getNode()?.attrs.id);
		if(!roofIndex) return;
		if (!(isIndividualPanelModeActive || enableEditFacet))
			dispatch(updateTransition({ currentTransitionState: true }));
		if (!roofIndexes.includes(roofIndex))
			dispatch(setRoofIndexes({ roofIndexes: [...roofIndexes, roofIndex] }));
	}

	const makeFacetSelectable = useCallback((facetgroup: Konva.Group) => {
		const roofIndex = getRoofSegmentIDFromGroupID(facetgroup.id());
		if (!roofIndex || roofIndex === selectedRoofIndex) return;
		dispatch(setSelectedRoofIndex({ selectedRoofIndex: roofIndex }));
	}, [selectedRoofIndex, dispatch]);

	const makeFacetTranslative = useCallback((facetgroup: Konva.Group) => {
		trRef.current?.detach();
		
		const panelGroup = facetgroup.findOne(`.${KonvaGroupNames.existingPanelsGroup}`) as Konva.Group;
		if (!panelGroup.hasChildren()) {
			dispatch(setSelectedRoofAzimuth({selectedRoofAzimuth: null}));
			return;
		}
		
		//rotate grp and counter rotate child to point transformer handle towards segments'azimuth
		if (!panelGroup.attrs.rotation && !azimuthEditedFacets[getRoofSegmentIDFromGroupID(facetgroup.id())]) {
			const roofSegmentID = getRoofSegmentIDFromGroupID(facetgroup.id());
			const rs = allRoofSegs.find(r => r.id === roofSegmentID);
			if (!rs) return;
			panelGroup?.rotation(rs.azimuthDegrees);
			panelGroup?.getChildren().forEach(e => e.rotation(-rs.azimuthDegrees));
		}

		panelGroup.draggable(true);
		trRef.current?.setNodes([panelGroup]);
		dispatch(setSelectedRoofAzimuth({selectedRoofAzimuth: Math.round((panelGroup.rotation()+360)%360)}));
	},[allRoofSegs, azimuthEditedFacets, dispatch]);

	function changePanelAlphanes(facetgroup: Konva.Group, action: 'ENTER' | 'LEAVE'){
		const panelGroup = facetgroup.findOne(`.${KonvaGroupNames.existingPanelsGroup}`) as Konva.Group | undefined;
		if (!panelGroup?.children?.length) return;
		panelGroup.moveToTop();
		trRef.current?.moveToTop();
		const lines = panelGroup?.children as Konva.Line[];
		for (let i = 0; i < lines.length; i++) {
			lines[i].attrs['fill'] = action === 'ENTER' ? FULL_TRANPARENT_BLACK_PANEL : SEMI_TRANPARENT_BLACK_PANEL;
		}
	}

	const roofSegments = useMemo(() => editModeEnabled ? allRoofSegs : Object.values(roofSegs),
		[editModeEnabled, allRoofSegs, roofSegs, enableEditFacet]);

	useEffect(() => {
		// reset transformations when roofSegments are updated in edit mode
		if (konvaRef?.current && editModeEnabled) {
			dispatch(setSelectedRoofIndex({ selectedRoofIndex: null }));
			resetAllTransformations(konvaRef?.current);
			konvaRef.current.container().style.cursor = 'default';
		}
	}, [allRoofSegs, konvaRef, currentActiveFacetEditMode, editModeEnabled, isIndividualPanelModeActive, enableEditFacet, dispatch]);

	useEffect(() => {
		if(enableEditFacet && roofSegments.length > 0) {
			const facets = roofSegments.map(eachFacet => ({segmentId: eachFacet.id, coordinates:eachFacet.simplifiedHullCoords, isEdited: false, isSelected: false}));
			dispatch(setEditableFacets({editableFacets:facets}));
		}
	}, [enableEditFacet, roofSegments]);

	const handleGroupClick = useCallback((event: KonvaEventObject<PointerEvent>) => {
		const targetFacetGroup = event.currentTarget as Konva.Group;

		targetFacetGroup.moveToTop();
		targetFacetGroup?.getLayer()?.batchDraw();

		if (!listenToEventsOnGroup) return;
		
		const previousSelectedGroup = currentSelectedFacetGroup.current;
		console.log('facet', targetFacetGroup.id());

		if (!previousSelectedGroup) {
			changePanelAlphanes(targetFacetGroup, 'ENTER');
			makeFacetTranslative(targetFacetGroup);
			makeFacetSelectable(targetFacetGroup);
			currentSelectedFacetGroup.current = targetFacetGroup;
			return;
		}

		if (previousSelectedGroup.id() !== targetFacetGroup.id()) {
			changePanelAlphanes(previousSelectedGroup, 'LEAVE');

			changePanelAlphanes(targetFacetGroup, 'ENTER');
			makeFacetTranslative(targetFacetGroup);
			makeFacetSelectable(targetFacetGroup);
			currentSelectedFacetGroup.current = targetFacetGroup;
			return;
		}

		if (previousSelectedGroup.id() === targetFacetGroup.id()) {
			changePanelAlphanes(targetFacetGroup, 'ENTER');
			makeFacetTranslative(targetFacetGroup);
			makeFacetSelectable(targetFacetGroup);
			return;
		}
	}, [listenToEventsOnGroup, makeFacetSelectable, makeFacetTranslative]);

	function handleContextMenuEvt(event: KonvaEventObject<PointerEvent>) {
		if (!listenToEventsOnGroup) return;
		event.evt.preventDefault();
		handleGroupClick(event);
		openEditFacetPopup({ x: event.evt.clientX, y: event.evt.clientY });
	}

	function openEditFacetPopup(position: Vector2d) {
		dispatch(setDropdownState({ open: true, position }));
	}

	function handleDragStart() {
		handleTouchEndOrCancel();
		if (!listenToEventsOnGroup) return;
		if (konvaRef?.current) {
			clearTimeout(longTouchTimerRef.current);
			konvaRef.current.container().style.cursor = 'move';
		}
	}

	function handleMouseOver(event: KonvaEventObject<PointerEvent>){
		if(!listenToEventsOnGroup) return;
		const targetFacetGroup = event.currentTarget as Konva.Group;
		const checkFacetHasPanels = !!targetFacetGroup.findOne(`.${KonvaGroupNames.existingPanelsGroup}`)?.hasChildren();
		if (konvaRef?.current)
			konvaRef.current.container().style.cursor = checkFacetHasPanels? 'move' : 'default';
	}

	return (
		<>
			{
				roofSegments.map((rs) => {
					if (deletedRoofIndexes.includes(rs.id)) return null;
					const groupId = `${getExistingFacetsGroupID(rs.id)}`;
					return (
						<Group
							key={rs.id}
							id={groupId}
							listening={listening}
							name={KonvaGroupNames.existingFacetsGroup}
							visible={!hideFacetGrpInIrradianceMode}
							onContextMenu={handleContextMenuEvt}
							onClick={handleGroupClick}
							onTap={handleGroupClick}
							onPointerOver={handleMouseOver}
							onTouchStart={(e) => {
								if(enableEditFacet) {
									return;
								}
								handleTouchStart(e);
							}}
							onTouchEnd={handleTouchEndOrCancel}
							onPointerLeave={() => {
								if (!listenToEventsOnGroup) return;
								if (konvaRef?.current)
									konvaRef.current.container().style.cursor = 'default';
							}}
							onDragStart={handleDragStart}
							onDragEnd={() => {
								if (!listenToEventsOnGroup) return;
								addRoofIndex();
								if (konvaRef?.current)
									konvaRef.current.container().style.cursor = 'default';
							}}
						>
							<PanelsGroup roofSegment={rs} />
							{editModeEnabled && (enableEditFacet ? <EditableFacet segmentId={rs.id} /> : <Facet roofSegment={rs} />)}
						</Group>
					);
				})
			}

			<TransformerComponent 
				ref={trRef}
				visible={!showIrradiance && !threeDModelEnabled}
				selectedRoofSegment={selectedRoofSegment} 
				onTransformEnd={addRoofIndex} />
		</>
	);
}
export default memo(FacetGroup);
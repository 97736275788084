import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Circle, Group, Line } from 'react-konva';
import Konva from 'konva';
import { FACET_HIGHLIGHT_COLOR, FACET_HIGHLIGHT_OFF_COLOR, KONVA_FACET_GROUP } from '../../../constants';
import { KonvaGroupNames } from '../utils';
import { useKonvaImageScale } from 'contexts/ToolDimensionsAndScaleContext';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { deletePoint, setEditableFacets } from 'store/slices/RoofDataSlice/roofDataSlice';
import { useLongPress } from 'hooks/useLongPress';
import { setDropdownState } from 'store/slices/EditFacetModalSlice';
import { useRefs } from 'contexts/RefContext';

interface Props extends Konva.LineConfig {
	segmentId: string;
}

const EditableFacet = (props: Props) => {
	const { segmentId } = props;
	const { enableEditFacet, editableFacets } = useSelector((state: RootState) => state.roofData.data);
	const {konvaRef} = useRefs();
	const currentSegment = editableFacets.find(eachSeg => eachSeg.segmentId === segmentId);	
	const [points, setPoints] = useState(currentSegment?.coordinates.flat() || []);
	const isDraggingRef = useRef(false);
	const scale = useKonvaImageScale();
	const dispatch = useDispatch();
	const pointScale = konvaRef?.current?.getAbsoluteScale().x || 1;
	
	function deleteFacetPoint(position: Vector2d, pointIndex: number, segmentId: string) {
		dispatch(deletePoint({pointIndex, segmentId}));
	}

	const { handleTouchStart, handleTouchEndOrCancel } = useLongPress({
		delay: 500, // ms
		enabled: true,
		onLongPress: deleteFacetPoint,
	});

	useEffect(() => {
		if (currentSegment) {
			setPoints(currentSegment.coordinates.flat());
		}
	}, [currentSegment]);

	const transformedPoints = useMemo(() => 
		points.map((point, index) => index % 2 === 0 ? point * scale.x : point * scale.y), 
	[points, scale.x, scale.y, enableEditFacet]);

	const handleDotDragMove = (e: Konva.KonvaEventObject<DragEvent>, index: number) => {
		const newPoints = [...points];
		newPoints[index] = e.target.x() / scale.x;
		newPoints[index + 1] = e.target.y() / scale.y;
		setPoints(newPoints);
	};

	const handleDotClick = (e: Konva.KonvaEventObject<MouseEvent>, index: number) => {
		e.evt.preventDefault();
		console.log(e.evt.button);
		if(e.evt.button !== 2) {
			return;
		}
		dispatch(deletePoint({pointIndex: index, segmentId}));
	};

	const handleDragEnd = (e: Konva.KonvaEventObject<DragEvent>, index: number) => {
		isDraggingRef.current = false;
		const chunkSize = 2;
		const convertedPoints: number[][] = [];
		for (let i = 0; i < points.length; i += chunkSize) {
			convertedPoints.push([points[i], points[i + 1]]);
		}

		const newUpdatedFacets = editableFacets.map(eachSegment => eachSegment.segmentId === segmentId
			? { ...eachSegment, coordinates: convertedPoints, isEdited: true }
			: eachSegment);
	
		dispatch(setEditableFacets({ editableFacets: newUpdatedFacets }));
	};

	const handlePointerClick = () => {
		const newUpdatedFacets = editableFacets.map(eachSegment => eachSegment.segmentId === segmentId
			? { ...eachSegment, isSelected: true }
			: {...eachSegment, isSelected: false});
	
		dispatch(setEditableFacets({ editableFacets: newUpdatedFacets }));
	};

	if (!points.length) return null; 

	return (
		<Group>
			<Line
				name={`${KonvaGroupNames.existingFacetsGroup}${KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR}facet`}
				id={currentSegment?.segmentId}
				points={transformedPoints}
				draggable={false}
				fill={currentSegment?.isEdited ?  'rgba(0, 128, 255, 0.3)' :  FACET_HIGHLIGHT_COLOR}
				stroke={'orange'}
				strokeWidth={2}
				closed={true}
				onPointerClick={handlePointerClick}
			/>
			{currentSegment?.isSelected && transformedPoints.map((_, index) => (
				index % 2 === 0 && (
					<Circle
						key={index}
						x={transformedPoints[index]}
						y={transformedPoints[index + 1]}
						radius={7 / pointScale}
						fill={'yellow'}
						draggable
						onContextMenu={(e) => {
							e.evt.preventDefault();
						}}
						onDragStart={() => {
							isDraggingRef.current = true;
						}}
						onDragMove={(e) => handleDotDragMove(e, index)}
						onDragEnd={(e) => handleDragEnd(e, index)}
						onClick={(e) => handleDotClick(e, index)}
						onTouchStart={(e) => {
							setTimeout(() => {
								console.log(isDraggingRef.current);
								if(isDraggingRef.current) return;
								handleTouchStart(e, {pointIndex: index, segmentId: currentSegment.segmentId});
							}, 150);
						}}
						onTouchEnd={handleTouchEndOrCancel}
					/>
				)
			))}
		</Group>);
};

export default EditableFacet;
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ModalPopup, ModalcontentInfo, ModalbuttonWrap, Modalbutton, ModalbuttonNo, ModaliconInfo } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { toggleDeleteModalState } from 'store/slices/DeleteModalSlice';
import { setDeletedRoofIndexes, setUnfinalizedFacet, removeNewlyCreatedFacets } from 'store/slices/ToolSlice';
import { useRefs } from 'contexts/RefContext';
import { KONVA_FACET_GROUP } from '../../../../constants';
import Konva from 'konva';

const deleteMsg = 'Are you sure you want to delete the facet?';
export default function DeleteModal() {
	const { open } = useSelector((state: RootState) => state.deleteModal.data);
	const { selectedRoofIndex, deletedRoofIndexes, selectedUnfinalizedKonvaFacetId, newlyCreatedFacets , drawModeEnabled} =
		useSelector((state: RootState) => state.toolNewPostions.data);
	const dispatch = useDispatch();
	const { konvaRef } = useRefs();

	function hanldeConfirmation() {
		if (drawModeEnabled && konvaRef?.current) {
			deleteFacets();
		} else {
			if (!selectedRoofIndex || deletedRoofIndexes.includes(selectedRoofIndex)) return;
			konvaRef?.current&& (konvaRef.current?.findOne('Transformer') as Konva.Transformer)?.setNodes([]);
			dispatch(setDeletedRoofIndexes({ deletedRoofIndexes: [...deletedRoofIndexes, selectedRoofIndex] }));
		}
		dispatch(toggleDeleteModalState({ value: false }));
	}

	function deleteFacets() {
		console.log('deltfacets',selectedRoofIndex,selectedUnfinalizedKonvaFacetId);
		if (!konvaRef?.current) return;
		if (!selectedUnfinalizedKonvaFacetId) return;
		const group = konvaRef.current.findOne(
			'#' + selectedUnfinalizedKonvaFacetId + KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR + KONVA_FACET_GROUP.IN_PROGRESS_GROUP_NAME
		) as Konva.Group;

		group.children?.forEach(child => {
			child.destroy();
		});
		group.destroy();

		if (newlyCreatedFacets[selectedUnfinalizedKonvaFacetId]) {
			dispatch(removeNewlyCreatedFacets({ id: selectedUnfinalizedKonvaFacetId }));
			dispatch(setUnfinalizedFacet({ id: null }));
		}
	}

	return (
		<Popup
			contentStyle={{ width: '560px', borderRadius: '4px' }}
			open={open}
			closeOnDocumentClick={false}
			onClose={() => {
				if (open)
					dispatch(toggleDeleteModalState({ value: false }));
			}}>
			<ModalPopup>
				<ModaliconInfo>
					<svg width="50" height="50" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx="38.5" cy="38.5" r="35.5" stroke="#E64650" strokeWidth="5.63"/>
						<path d="M38.5 19.7692V44.5035" stroke="#E64650" strokeWidth="5.63" strokeLinecap="round"/>
						<circle cx="38.6229" cy="53.0671" r="2.815" fill="#E64650" stroke="#E64650" strokeWidth="1.9596"/>
					</svg>
				</ModaliconInfo>
				<ModalcontentInfo>
					{deleteMsg}
				</ModalcontentInfo>

				<ModalbuttonWrap>
					
					<ModalbuttonNo onClick={() => {
						dispatch(setUnfinalizedFacet({id: null}));
						dispatch(toggleDeleteModalState({ value: false }));
					}}>
						No
					</ModalbuttonNo>
					<Modalbutton onClick={hanldeConfirmation}>
						Yes
					</Modalbutton>
				</ModalbuttonWrap>
			</ModalPopup>
		</Popup>
	);
}

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ModalPopup, ModalcontentInfo, ModalbuttonWrap, Modalbutton, ModalbuttonNo,
	ModelContent,ModelLabelValueWrapper,ModalMultiPanelsWrap, MulitiPanelsSwitchWrapper, ModalToggle } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { toggleAddPanelModalState, toggleHorizontalState } from 'store/slices/AddPanelModalSlice/addPanelModalSlice';
import { ORIENTATION } from 'store/slices/AddPanelModalSlice/types';
import { setAddMultiplePanel } from 'store/slices/PanelSlice';
interface AddPanelModalProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export default function AddPanelModal({ onConfirm, onCancel }: AddPanelModalProps) {
	
	const dispatch = useDispatch<AppDispatch>();
	const { open, orientation } = useSelector((state: RootState) => state.addPanelModal.data);
	const { enableMultipleAddPanel } = useSelector((state: RootState) => state.panelSlice.data);
	const handleConfirm = async () => {
		dispatch(toggleAddPanelModalState({ value: false }));
		onConfirm();
	};

	const handleCancel = () => {
		dispatch(toggleAddPanelModalState({ value: false }));
		onCancel();
	};

	return (
		<Popup
			contentStyle={{ width: '632px', borderRadius: '4px', marginTop: '120px' }}
			open={open}
			closeOnDocumentClick={false}
			onClose={() => {
				dispatch(toggleAddPanelModalState({ value: false }));
			}}>
			<ModalPopup>
				<ModalcontentInfo>
					Add Panel 
				</ModalcontentInfo>
				<ModelContent>
					<ModelLabelValueWrapper onClick={()=>dispatch(toggleHorizontalState({ value: ORIENTATION.DEFAULT}))}>
						<svg xmlns="http://www.w3.org/2000/svg" width="56" height="55" viewBox="0 0 56 55" fill="none">
							<rect x="29.4961" y="4" width="24.9995" height="41.6658" fill="white
							" stroke="black" strokeOpacity="0.88" strokeWidth="2"/>
							<rect x="45.6641" y="29" width="24.9995" height="41.6658" transform="rotate(90 45.6641 29)"
								fill="white" stroke="black" strokeOpacity="0.88" strokeWidth="2"/>
							<path d="M5.8296 25.4084L12.221 15.7915L0.696909 15.0648L5.8296 
							25.4084ZM27.4958 5.4162L17.2816 0.0308007L17.7248 11.5693L27.4958 
							5.4162ZM7.35109 16.7231C9.03791 11.2851 13.402 7.99257 18.7333 6.7349L18.2741 
							4.78833C12.4112 6.17141 7.38146 9.87454 5.44088 16.1305L7.35109 16.7231Z" 
							fill="black" fillOpacity="0.88"/>
						</svg>
						<label>
						
							<input
								type="radio"
								value={ORIENTATION.DEFAULT}
								checked={orientation == ORIENTATION.DEFAULT}
								onChange={()=>dispatch(toggleHorizontalState({ value: ORIENTATION.DEFAULT}))}
							/>
					Default
						</label>
					</ModelLabelValueWrapper>
					<ModelLabelValueWrapper onClick={()=>dispatch(toggleHorizontalState({ value: ORIENTATION.LANDSCAPE}))}>
						<svg xmlns="http://www.w3.org/2000/svg" width="52" height="32" viewBox="0 0 52 32" fill="none">
							<rect x="51" y="1" width="30" height="50" transform="rotate(90 51 1)" 
								fill="white" stroke="black" strokeOpacity="0.88" strokeWidth="2"/>
						</svg>
						<label>
							<input
								type="radio"
								value={ORIENTATION.LANDSCAPE}
								checked={orientation == ORIENTATION.LANDSCAPE}
								onChange={()=>dispatch(toggleHorizontalState({ value: ORIENTATION.LANDSCAPE }))}
							/>
					Landscape
						</label>
					</ModelLabelValueWrapper>
					<ModelLabelValueWrapper onClick={()=>dispatch(toggleHorizontalState({ value: ORIENTATION.PORTRAIT}))}>
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="52" viewBox="0 0 32 52" fill="none">
							<rect x="1" y="1" width="30" height="50" fill="white" stroke="black" strokeOpacity="0.88" strokeWidth="2"/>
						</svg>
						<label>
							<input
								type="radio"
								value={ORIENTATION.PORTRAIT}
								checked={orientation == ORIENTATION.PORTRAIT}
								onChange={()=>dispatch(toggleHorizontalState({ value: ORIENTATION.PORTRAIT }))}
							/>
					Portrait
						</label>
					</ModelLabelValueWrapper>
					<MulitiPanelsSwitchWrapper>
						<ModalMultiPanelsWrap className="enable-multipanels">
							<svg xmlns="http://www.w3.org/2000/svg" width="60" height="34" viewBox="0 0 60 34" fill="none">
								<path d="M54.8302 15.2222L58 33H40.0377M54.8302 15.2222L52.2943 1H37.7132M54.8302 15.2222H5.28721M5.28721 
							15.2222L7.91698 1H22.9208M5.28721 15.2222L2 33H20.9132M37.7132 1L40.0377 33M37.7132 1H22.9208M40.0377 
							33H20.9132M22.9208 1L20.9132 33" stroke="#1F1F1F" strokeWidth="2"/>
							</svg>
							<label>
								<input
									type="radio"
									value=""
								/>
						Enable Multiple panels
							</label>
						</ModalMultiPanelsWrap>
						<ModalToggle>
							<div className='switchButton bg-transparent'>
								<span style={{width: '22px'}}>On</span>
								<div className="toggle-button-cover">
									<div className="button-cover">
										<div className="button b2" id="button-13">
											<input
												checked={enableMultipleAddPanel}
												type="checkbox"
												className="checkbox"
												onChange={() => dispatch(setAddMultiplePanel({shouldEnable:!enableMultipleAddPanel}))}
												// disabled={diableEditToggle}
											/>
											<div className="knobs">
												<span></span>
											</div>
											<div className="layer"></div>
										</div>
									</div>
								</div>
							</div>

						</ModalToggle>
					</MulitiPanelsSwitchWrapper>
				</ModelContent>
				
				<ModalbuttonWrap>
					<ModalbuttonNo onClick={handleCancel}>
						No
					</ModalbuttonNo>
					<Modalbutton onClick={handleConfirm}>
						Yes
					</Modalbutton>
				</ModalbuttonWrap>
			</ModalPopup>
		</Popup>
	);
}
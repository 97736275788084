/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { getExistingPanelsGroupID } from 'components/tool/utils';
import { useRefs } from 'contexts/RefContext';
import Konva from 'konva';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setEditedAzimuthFacet, setInvalidAzimuthFacet, setRoofIndexes, setSelectedRoofAzimuth, updateTransition } from 'store/slices/ToolSlice';
import { debounce } from '../../../../../util';
import { StripDiv, StripDivEdit, InvalidAzimuthWrap } from '../Panel/AddPanelButton/style';
import Button from 'components/Basic/Button';

interface ShapeConfig {
	x: number;
	y: number;
	width: number;
	height: number;
	rotation: number;
}

interface Point {
	x: number;
	y: number;
}

function getCenter(shape: ShapeConfig) {
	return {
		x: shape.x +
			(shape.width / 2) * Math.cos(shape.rotation) +
			(shape.height / 2) * Math.sin(-shape.rotation),
		y: shape.y +
			(shape.height / 2) * Math.cos(shape.rotation) +
			(shape.width / 2) * Math.sin(shape.rotation),
	};
}

function rotateAroundPoint(shape: ShapeConfig, angleRad: number, point: Point): ShapeConfig {
	const x = point.x +
		(shape.x - point.x) * Math.cos(angleRad) -
		(shape.y - point.y) * Math.sin(angleRad);
	const y = point.y +
		(shape.x - point.x) * Math.sin(angleRad) +
		(shape.y - point.y) * Math.cos(angleRad);
	return {
		...shape,
		rotation: shape.rotation + angleRad,
		x,
		y
	};
}

function rotateAroundCenter(shape: ShapeConfig, deltaRad: number): ShapeConfig {
	const center: Point = getCenter(shape);
	return rotateAroundPoint(shape, deltaRad, center);
}

function ShowCurrentAzimuth() {
	const { allRoofSegs } = useSelector((state: RootState) => state.roofData.data);
	const { selectedRoofAzimuth, selectedRoofIndex, azimuthEditedFacets, invalidAzimuthFacets } = 
		useSelector((state: RootState) => state.toolNewPostions.data);
	const [azimuth, setAzimuth] = useState<number | null>(selectedRoofAzimuth ?? 0);
	const editable = useMemo(()=> {
		return azimuthEditedFacets[selectedRoofIndex!];
	}, [azimuthEditedFacets, selectedRoofIndex]);
	const invalid = useMemo(()=> {
		return invalidAzimuthFacets[selectedRoofIndex!];
	}, [invalidAzimuthFacets, selectedRoofIndex]);
	const { konvaRef } = useRefs();
	const dispatch = useDispatch();
	const { roofIndexes } = useSelector((state: RootState) => state.toolNewPostions.data);

	const [maxAzim, minAzim, orginalAzim] = useMemo(() => {
		const azim = allRoofSegs.find((roof) => roof.id === selectedRoofIndex)?.azimuthDegrees;
		if (azim == null) return [0, 0];
		return [Math.round(((azim + 30) + 360) % 360), Math.round(((azim - 30) + 360) % 360), azim];
	}, [selectedRoofIndex, allRoofSegs]);

	const handleRotation = useCallback((newAzimuth: number): void => {
		const debouncedRotation = debounce(() => {
			if (!selectedRoofIndex) return;
			const transformer = konvaRef?.current?.findOne('Transformer') as Konva.Transformer;
			const panelGroup = konvaRef?.current?.findOne(`#${getExistingPanelsGroupID(selectedRoofIndex)}`) as Konva.Group;
			if (!panelGroup || !transformer || !panelGroup.hasChildren()) return;
			const attrs = transformer._getNodeRect();
			const diff = newAzimuth - transformer.getAbsoluteRotation();
			const shape = rotateAroundCenter(attrs, diff * (Math.PI / 180));
			transformer._fitNodesInto(shape);
		}, 25);
		debouncedRotation();
	}, [konvaRef, selectedRoofIndex]);

	const validateAzimuth = (value: number | null): boolean => {
		if (value === null) return false;
		if (minAzim < maxAzim) {
			if (value < minAzim || value > maxAzim) {
				return false;
			}
		} else {
			if (value > maxAzim && value < minAzim) {
				return false;
			}
		}
		return true;
	};

	const debouncedValidateInput = useCallback(
		(value: number | null) => {
			debounce(() => {
				if (value === null){
					dispatch(setInvalidAzimuthFacet({facetName: selectedRoofIndex!, invalid:true}));
					return;
				}
				if (minAzim < maxAzim) {
					if (value < minAzim || value > maxAzim) {
						dispatch(setInvalidAzimuthFacet({facetName: selectedRoofIndex!, invalid:true}));
						return;
					}
				} else {
					if (value > maxAzim && value < minAzim) {
						dispatch(setInvalidAzimuthFacet({facetName: selectedRoofIndex!, invalid:true}));
						return;
					}
				}
				dispatch(setInvalidAzimuthFacet({facetName: selectedRoofIndex!, invalid:false}));
			}, 500)();
		},
		[minAzim, maxAzim, dispatch, selectedRoofIndex]
	);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let value = parseInt(event.target.value, 10);
		if (isNaN(value) || value == null) {
			setAzimuth(null);
			dispatch(setInvalidAzimuthFacet({facetName: selectedRoofIndex!, invalid:true}));
			dispatch(updateTransition({ currentTransitionState: true }));
			debouncedValidateInput(null);
			dispatch(setRoofIndexes({ roofIndexes: [...roofIndexes, selectedRoofIndex!] }));
			return;
		}
		value = value < 0 ? (value + 360) % 360 : value % 360;
		setAzimuth(+value);
		handleRotation(+value);
		dispatch(setSelectedRoofAzimuth({ selectedRoofAzimuth: value }));
		dispatch(updateTransition({ currentTransitionState: true }));
		dispatch(setRoofIndexes({ roofIndexes: [...roofIndexes, selectedRoofIndex!] }));
		// debouncedValidateInput(value);
	};

	const onBlurHandler = () => {
		setIsFocused(false);
		debouncedValidateInput(azimuth);
	};

	useEffect(() => {
		setAzimuth(selectedRoofAzimuth);
		debouncedValidateInput(selectedRoofAzimuth);
	}, [debouncedValidateInput, selectedRoofAzimuth, selectedRoofIndex]);

	console.log(minAzim, maxAzim, orginalAzim, selectedRoofAzimuth);

	const [isFocused, setIsFocused] = useState(false);

	return (
		<>
			{!editable ?
				(
					<StripDiv
						className='azimuthStrip'
						onClick={() => dispatch(setEditedAzimuthFacet({facetName: selectedRoofIndex!, edited:true}))}>
						Edit Azimuth
						:
						<span style={{ 
							color: '#003CFF', 
							fontSize: '1.15rem', 
							fontWeight: '600', 
							marginLeft: '5px',
							marginRight: '2px' 
						}}>{selectedRoofAzimuth}
							<sup>°</sup></span>
						<svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M22 10.7519C22 11.0312 21.9463 11.3033 21.8389 11.5683C21.7314 11.8333 21.5775 12.066 
								21.377 12.2666L13.6748 19.9687L9.625 20.9785L10.6348 16.9287L18.3369 9.23725C18.5374 9.03673 
								18.7702 8.88992 19.0352 8.79682C19.3001 8.70373 19.5723 8.65718 19.8516 8.65718C20.1523 8.65718 
								20.4316 8.70731 20.6895 8.80757C20.9473 8.90783 21.1764 9.05464 21.377 9.248C21.5775 9.44136 
								21.7279 9.65978 21.8281 9.90327C21.9284 10.1468 21.9857 10.4296 22 10.7519ZM20.625 10.7519C20.625 
								10.5156 20.5534 10.333 20.4102 10.2041C20.2669 10.0751 20.0807 10.0107 19.8516 10.0107C19.7513 
								10.0107 19.6546 10.025 19.5615 10.0537C19.4684 10.0823 19.3861 10.1324 19.3145 10.2041L11.8809 
								17.6376L11.5156 19.0878L12.9658 18.7226L20.3994 11.2998C20.5498 11.1494 20.625 10.9667 20.625 
								10.7519ZM13.2988 11.8369C13.4635 11.5647 13.5781 11.2711 13.6426 10.956C13.707 10.6409 13.7428 
								10.3222 13.75 9.99995C13.75 9.43419 13.6426 8.90067 13.4277 8.39936C13.2129 7.89806 12.9193 7.46121 
								12.5469 7.08882C12.1745 6.71642 11.734 6.41922 11.2256 6.19722C10.7171 5.97521 10.1836 5.86779 
								9.625 5.87495C9.05208 5.87495 8.51497 5.98237 8.01367 6.19722C7.51237 6.41206 7.07552 6.70568 
								6.70312 7.07807C6.33073 7.45047 6.03711 7.88732 5.82227 8.38862C5.60742 8.88992 5.5 9.42703 
								5.5 9.99995C5.5 10.5729 5.60742 11.11 5.82227 11.6113C6.03711 12.1126 6.33073 12.5494 6.70312 
								12.9218C7.07552 13.2942 7.51237 13.5878 8.01367 13.8027C8.51497 14.0175 9.05208 14.1249 9.625 
								14.1249C9.94727 14.1249 10.2624 14.0891 10.5703 14.0175C10.8783 13.9459 11.1755 13.8313 11.4619 
								13.6738L9.63574 15.4999H9.625C8.85872 15.4999 8.14258 15.3567 7.47656 15.0703C6.81055 14.7838 
								6.23047 14.3935 5.73633 13.8994C5.24219 13.4052 4.84831 12.8216 4.55469 12.1484C4.26107 11.4752 
								4.11784 10.7591 4.125 9.99995C4.125 9.24084 4.26823 8.52827 4.55469 7.86225C4.84115 7.19624 
								5.23145 6.61258 5.72559 6.11128C6.21973 5.60998 6.80339 5.2161 7.47656 4.92964C8.14974 4.64318 
								8.86589 4.49995 9.625 4.49995C10.3913 4.49995 11.1074 4.64318 11.7734 4.92964C12.4395 5.2161 
								13.0231 5.60998 13.5244 6.11128C14.0257 6.61258 14.416 7.19982 14.6953 7.873C14.9746 8.54617 
								15.1178 9.26232 15.125 10.0214L13.2988 11.8369ZM8.55078 18.1748L8.21777 19.5175C7.43001 19.4101 
								6.68164 19.2096 5.97266 18.916C5.26367 18.6223 4.60482 18.2571 3.99609 17.8203C3.38737 17.3834 
								2.83594 16.875 2.3418 16.2949C1.84766 15.7148 1.42871 15.0917 1.08496 14.4257C0.741211 13.7597 
								0.476237 13.0507 0.290039 12.2988C0.103841 11.5468 0.00716146 10.7805 0 9.99995C0 9.11909 0.114583 
								8.27046 0.34375 7.45405C0.572917 6.63765 0.895182 5.87137 1.31055 5.15522C1.72591 4.43908 2.23079 
								3.78738 2.8252 3.20015C3.4196 2.61291 4.06771 2.1116 4.76953 1.69624C5.47135 1.28087 6.23763 
								0.955028 7.06836 0.7187C7.89909 0.482372 8.7513 0.367788 9.625 0.37495C10.6849 0.37495 11.6947 
								0.536083 12.6543 0.858348C13.6139 1.18061 14.4948 1.63537 15.2969 2.22261C16.099 2.80985 16.7972 
								3.51883 17.3916 4.34956C17.986 5.18029 18.4515 6.09337 18.7881 7.08882C18.5589 7.16043 18.3441 
								7.23921 18.1436 7.32515C17.943 7.41108 17.7425 7.53283 17.542 7.69038C17.2842 6.80952 16.8975 
								6.00386 16.3818 5.27339C15.8662 4.54292 15.2611 3.91987 14.5664 3.40425C13.8717 2.88862 13.1055 
								2.484 12.2676 2.19038C11.4297 1.89676 10.5488 1.74995 9.625 1.74995C8.86589 1.74995 8.13542 1.84663 
								7.43359 2.03999C6.73177 2.23335 6.0765 2.51265 5.46777 2.87788C4.85905 3.24311 4.30046 3.6728 
								3.79199 4.16694C3.28353 4.66108 2.85384 5.2161 2.50293 5.83198C2.15202 6.44787 1.8763 7.10672 
								1.67578 7.80854C1.47526 8.51037 1.375 9.24084 1.375 9.99995C1.375 10.6803 1.45736 11.3427 1.62207 
								11.9873C1.78678 12.6318 2.01953 13.2441 2.32031 13.8242C2.62109 14.4042 2.98991 14.9414 3.42676 
								15.4355C3.86361 15.9296 4.34342 16.3665 4.86621 16.746C5.389 17.1256 5.96549 17.4371 6.5957 
								17.6806C7.22591 17.9241 7.8776 18.0888 8.55078 18.1748ZM11 9.99995C11 10.1933 10.9642 10.3723 
								10.8926 10.5371C10.821 10.7018 10.7243 10.845 10.6025 10.9667C10.4808 11.0885 10.334 11.1888 
								10.1621 11.2675C9.99023 11.3463 9.8112 11.3821 9.625 11.3749C9.43164 11.3749 9.2526 11.3391 9.08789 
								11.2675C8.92318 11.1959 8.77995 11.0992 8.6582 10.9775C8.53646 10.8557 8.4362 10.7089 8.35742 
								10.5371C8.27865 10.3652 8.24284 10.1861 8.25 9.99995C8.25 9.80659 8.28581 9.62755 8.35742 
								9.46284C8.42904 9.29813 8.52572 9.1549 8.64746 9.03315C8.76921 8.91141 8.91602 8.81115 9.08789 
								8.73237C9.25977 8.6536 9.4388 8.61779 9.625 8.62495C9.81836 8.62495 9.9974 8.66076 10.1621 
								8.73237C10.3268 8.80399 10.4701 8.90067 10.5918 9.02241C10.7135 9.14416 10.8138 9.29097 10.8926 
								9.46284C10.9714 9.63472 11.0072 9.81375 11 9.99995Z" fill="#64686A" />
						</svg>
					</StripDiv>
				)
				:
				(
					<StripDivEdit>
						<label
							style={{
								color: '#223349',
								fontSize: '1.01rem',
							}}
							htmlFor="azimuth">
							Azimuth:
						</label>
						<input
							onFocus={() => setIsFocused(true)}
							onBlur={onBlurHandler}
							id="azimuth-input"
							type="number"
							value={azimuth ?? ''}
							onChange={handleChange}
							style={{
								borderColor:
									invalid
										? '#FF0C0C' // Red for invalid input
										: isFocused
											? '#223349' // Blue when focused
											: '#e9e9e9', // Default,
							}}
						/>
						{
							invalid && (<InvalidAzimuthWrap>
								<span>Invalid Azimuth</span>
								<Button
									id="azimuth-alert"
									tooltipContent='Enter Azimuth in range of the current azimuth +- 30'
								>
									<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 
										16 3.5816 16 8C16 12.4184 12.4184 16 8 16ZM8 14.4C9.69739 14.4 11.3253 13.7257 
										12.5255 12.5255C13.7257 11.3253 14.4 9.69739 14.4 8C14.4 6.30261 13.7257 4.67475 
										12.5255 3.47452C11.3253 2.27428 9.69739 1.6 8 1.6C6.30261 1.6 4.67475 2.27428 
										3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 9.69739 2.27428 11.3253 
										3.47452 12.5255C4.67475 13.7257 6.30261 14.4 8 14.4ZM8 4C8.21217 4 8.41566 4.08429 
										8.56569 4.23431C8.71571 4.38434 8.8 4.58783 8.8 4.8V8.8C8.8 9.01217 8.71571 9.21566 
										8.56569 9.36569C8.41566 9.51571 8.21217 9.6 8 9.6C7.78783 9.6 7.58434 9.51571 
										7.43431 9.36569C7.28429 9.21566 7.2 9.01217 7.2 8.8V4.8C7.2 4.58783 7.28429 4.38434 
										7.43431 4.23431C7.58434 4.08429 7.78783 4 8 4ZM8 12C7.78783 12 7.58434 11.9157 
										7.43431 11.7657C7.28429 11.6157 7.2 11.4122 7.2 11.2C7.2 10.9878 7.28429 10.7843 
										7.43431 10.6343C7.58434 10.4843 7.78783 10.4 8 10.4C8.21217 10.4 8.41566 10.4843 
										8.56569 10.6343C8.71571 10.7843 8.8 10.9878 8.8 11.2C8.8 11.4122 8.71571 11.6157 
										8.56569 11.7657C8.41566 11.9157 8.21217 12 8 12Z" fill="#FF0C0C" />
									</svg>
								</Button>
							</InvalidAzimuthWrap>
							)
						}
					</StripDivEdit>
				)
			}
			{/* {
				invalidAzimuthInput && !validateAzimuth(azimuth) && (
					<StripDiv
						style={{ color: '#2E384D', lineHeight: '1.5', fontSize: '1rem' }}>
						Invalid Azimuth: Enter Azimuth in range the of original azimuth ± 30.
					</StripDiv>
				)
			} */}
		</>
	);
}

export default ShowCurrentAzimuth;
import { AxiosError } from 'axios';
import { updateEditableFacets } from 'components/tool/Editor/ButtonDiv/api';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from 'store';
import { errorResponseHandler } from 'store/api/AxiosError';

export default function useEditFacetQuery() {
	const { uuid, editableFacets, panel } = useSelector((state: RootState) => state.roofData.data);
	const currentEditableFacets = editableFacets.filter(eachSeg => eachSeg.isEdited).map(eachSeg => ({segmentId:eachSeg.segmentId, coordinates: eachSeg.coordinates}));
	return useMutation(
		'update-edited-facet',
		async () => await updateEditableFacets(currentEditableFacets, uuid, panel.key),
		{
			onError(error: AxiosError) {
				const message = errorResponseHandler(error).message;
				toast.error(message);
			}
		}
	);
}
import { getAbsolutePoints, transformPointsToOriginalScale } from 'components/DisplayEnergy/util';
import Konva from 'konva';
import { normalizeAngle } from '../utils';
import { getRoofSegmentIDFromGroupID, KonvaGroupNames } from '../utils';
import { DESIGN_MODES, DEFAULT_SHADING_VAL, GROUND_MOUNT_DEFAULT_PITCH, NORMAL_FACET_DEFAULT_PITCH } from '../../../constants';
import { NewlyCreatedFacet } from 'store/slices/ToolSlice/types';

type TranslatePosPayload = {
	segmentId: string,
	azimuth: number,
	previousAzimuthDegrees: number,
	translatedPos: {
		[id: string]: {
			pos: number[][]
		}
	};
	pitch?: number;
	shading?: number;
	previousShading?: number;
	previousPitchDegrees?: number;

}

function getAbsolutePointsOfTranslatedPanels(stage: Konva.Stage, roofIndexes: string[],
	allRoofSegs: RasterRoofSegment[], currentScale: Vector2d
) {
	try {
		const panelGroups = stage.find(`.${KonvaGroupNames.existingPanelsGroup}`)
			.filter(g => roofIndexes.includes(getRoofSegmentIDFromGroupID(g.attrs.id))) as Konva.Group[];

		const newPos: TranslatePosPayload[] = [];
		// const stagePosition = stage.position();

		panelGroups?.forEach((g) => {
			const translatedPos: typeof newPos[0]['translatedPos'] = {};
			const segmentId = getRoofSegmentIDFromGroupID(g.attrs.id);

			g.children?.forEach((child) => {
				if (child.className === 'Line') {
					const newPoints = (getAbsolutePoints(child as Konva.Line, currentScale, stage) as number[]);
					//translated postion for panels 
					translatedPos[child.attrs.id] = {
						pos: [...transformFlatArrayToCoordinateTypeArray(newPoints)]

					};
				}
			});
			const roofSegment = allRoofSegs.find(rs => rs.id === segmentId);
			// todo push like previously or continue like this
			const newPitch = g.getAttr('pitch');
			const shading = g.getAttr('shading');
			if (!roofSegment) return [];
			newPos.push({
				segmentId,
				azimuth: normalizeAngle(g.rotation() - roofSegment.azimuthDegrees),
				previousAzimuthDegrees: roofSegment.azimuthDegrees,
				translatedPos,
				previousPitchDegrees: roofSegment.pitchDegrees,
				pitch: newPitch ?? roofSegment.pitchDegrees,
				shading: shading ?? roofSegment.shading,
				previousShading: roofSegment.shading,
			});

		});
		console.log(newPos);
		return newPos;
	} catch (e) {
		console.log('errr', e);
		throw new Error('E_WHILE_GETTING_TRANSLATION_FOR_SEGMENT');
	}
}

function transformFlatArrayToCoordinateTypeArray(list: number[]): number[][] {
	const transformedArray = [];
	for (let i = 0; i <= list.length - 2; i += 2) {
		transformedArray.push([list[i], list[i + 1]]);
	}
	return transformedArray;
}

function transformNewlyAddedFacets(params: { mode: DesignModes, 
	newlyCreatedFacets: Record<string, NewlyCreatedFacet>, 
	scale: Vector2d, 
}) {

	const { mode, newlyCreatedFacets, scale } = params;
	if (!Object.keys(newlyCreatedFacets).length) {
		return [];
	}

	if (mode !== DESIGN_MODES.DEFAULT) {
		return Object.values(newlyCreatedFacets).map(facet => ({
			hullCoords: facet.hullCoords.map(coord => (transformPointsToOriginalScale(scale, coord))),
			azimuthDegrees: facet.azimuthDegrees,
			pitchDegrees: facet.pitchDegrees ? +facet.pitchDegrees : facet?.isGroundMount ? GROUND_MOUNT_DEFAULT_PITCH : NORMAL_FACET_DEFAULT_PITCH,
			shading: facet.shading ? +facet.shading : DEFAULT_SHADING_VAL,
			isGroundMount: facet?.isGroundMount,
			noGapGroundMount: facet?.noGapGroundMount,
		}));
	}

	return Object.values(newlyCreatedFacets).map(facet => ({
		hullCoords: facet.hullCoords.map(coord => (transformPointsToOriginalScale(scale, coord))),
		isGroundMount: facet?.isGroundMount,
		noGapGroundMount: facet?.noGapGroundMount,
		azimuthDegrees: facet.azimuthDegrees,
		pitchDegrees: facet.pitchDegrees ? +facet.pitchDegrees : facet?.isGroundMount ? GROUND_MOUNT_DEFAULT_PITCH : NORMAL_FACET_DEFAULT_PITCH,
		shading: facet.shading ? +facet.shading : DEFAULT_SHADING_VAL,
	}));
}

function checkIfLayoutNeedsToBeReset(stage: Konva.Stage, roofIndexes: string[], allRoofSegs: RasterRoofSegment[]) {

	const panelGroups = stage?.find(`.${KonvaGroupNames.existingPanelsGroup}`).
		filter(g => roofIndexes.includes(getRoofSegmentIDFromGroupID(g.attrs.id))) as Konva.Group[];

	for (const g of panelGroups || []) {
		const segmentId = getRoofSegmentIDFromGroupID(g.attrs.id);
		const roofSegment = allRoofSegs.find((rs) => rs.id === segmentId);
		const newPitch = g.getAttr('pitch');

		// reset layout if pitch has chnaged;
		if ((newPitch || newPitch === 0) && newPitch !== roofSegment?.pitchDegrees) {
			return true;
		}
	}

	return false;
}

const downloadDataAsJSONFile = (data: object, fileName: string) => {
	const jsonData = JSON.stringify(data, null, 2);
	const blob = new Blob([jsonData], { type: 'application/json' });
	const link = document.createElement('a');
	link.href = URL.createObjectURL(blob);
	link.download = `${fileName}.json`;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export { transformNewlyAddedFacets, 
	getAbsolutePointsOfTranslatedPanels, 
	transformFlatArrayToCoordinateTypeArray ,
	checkIfLayoutNeedsToBeReset,
	downloadDataAsJSONFile
};

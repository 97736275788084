import {
	AppBoxWrap,
	EnergyDiv,
	ToolDiv,
	AppFlexBoxWrap,
} from './styles';
import ToolWithSilder from '../../components/ToolWithSlider.tsx';
import DisplayEnergy from '../../components/DisplayEnergy';
import Loader, { Loader_Type } from '../../components/Loader';
import { MIN_PANELS_REQUIRED_FOR_DESIGN_TO_QUALIFY, PARENT_EVENTS, SOlAR_QOUTE_APP_NAME } from '../../constants';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { RefProvider } from '../../contexts/RefContext';
import Tool from 'components/tool';
import { useIsMutating } from 'react-query';
import { useOrgTheme } from 'contexts/ThemeContext';
import ToolDimensionsAndScaleProvider from 'contexts/ToolDimensionsAndScaleContext';

export default function LoadRoofDesign() {
	const { data: currentRoofState, loading } = useSelector((state: RootState) => state.roofData);
	const { parentAppName } = useSelector((state: RootState) => state.QueryParams.data);
	const { fullScreenEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	const { org } = useSelector((state: RootState) => state.roofData.data);
	const { mainHeading } = useOrgTheme();
	const switchingImageryProvider = useIsMutating({ mutationKey: [`switch-imagery-${currentRoofState.imagerySource}`] });
	const isUserChangingDesignMode = useIsMutating({ mutationKey: ['switch-design-mode'] });
	console.log('switchModesQuery loding main', isUserChangingDesignMode);

	if (loading || switchingImageryProvider || isUserChangingDesignMode ) {
		let type = Loader_Type.DEFAULT;
		if(org.setting?.isB2C) type = Loader_Type.SLIDER;
		else if(!loading) type = Loader_Type.INDEFINITE;

		return <Loader loaderText= {loading ? 'loading' : ''} type={type}/>;
	}

	const notEnoughPanelsToMoveForward = !currentRoofState.org.setting.canEdit &&
		currentRoofState.rasterSolarPanels.length < MIN_PANELS_REQUIRED_FOR_DESIGN_TO_QUALIFY;

	if (notEnoughPanelsToMoveForward) {
		window.parent.postMessage({ id: PARENT_EVENTS.DESIGN_HAS_LESS_THEN_REQUIRED_PANELS }, '*');
		return null;
	} else {
		window.parent.postMessage({ id: PARENT_EVENTS.DESIGN_HAS_SUFFICIENT_PANELS, 
			detail: { maxPanels: currentRoofState.rasterSolarPanels.length } }, '*');
	}

	return (
		<div className="bodyWrap" >
			<AppBoxWrap fullScreenEnabled={fullScreenEnabled}>
				<div className='design-Container' >
					{!!mainHeading &&
						<div className='design-head'>
							<h3 className='title mobileview'>{mainHeading}</h3>
						</div>}
					<div className='design-body'>
						<RefProvider>
							<ToolDimensionsAndScaleProvider>
								<AppFlexBoxWrap fullScreenEnabled={fullScreenEnabled} isParentSQ={parentAppName === SOlAR_QOUTE_APP_NAME}>
									<ToolDiv fullScreenEnabled={fullScreenEnabled} isParentSQ={parentAppName === SOlAR_QOUTE_APP_NAME}>
										<ToolWithSilder />
									</ToolDiv>
									<EnergyDiv isParentSQ={parentAppName === SOlAR_QOUTE_APP_NAME} fullScreenEnabled={fullScreenEnabled}>
										<div className='w-full relative'>
											{/* <div className='bar-loader'></div> */}
											<Tool />
										</div>
										<DisplayEnergy />
									</EnergyDiv>
								</AppFlexBoxWrap>
							</ToolDimensionsAndScaleProvider>
						</RefProvider>
					</div>
				</div>
			</AppBoxWrap>
		</div>
	);
}

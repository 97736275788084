import styled, { } from 'styled-components';

export const PanelOrientationWrap = styled.div`
			display: flex;
			align-items: center;
    	pointer-events: all;
			overflow: hidden;
			// border: 1px solid rgb(228,228,228);
			// border-radius: 91.4939px;
			// position: absolute;
			// top: 40px;
    	// left: 50px;
`;

export const LabelOrientation = styled.label`
	display: flex;
	align-items: center;
	cursor: pointer;
	border: 0px;
	border-left: 0px;
	border-right: 1px solid #E4E4E4;
	color: #28373E;
	font-size: 0.938rem;
	padding: 0px .65rem;
	background: #fff;
	height: 30px;
	width: auto;
	border-radius: 0px;
	min-width: 40px;

	&#multiple-panel{
		padding-left: 0.25rem;
	}
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

`;

export const LabelWithSwitch = styled.div`{
	display: flex;
 gap: .25rem;
`;
export const ModalToggle = styled.div`{
	display: flex;
	margin-right: 5px;

	.switchButton .button{
	width: 35px;
	height: 22px;
}
		
.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;

	&:disabled{
		cursor: not-allowed;
	}
}

.knobs {
  z-index: 2;
  background: #DADBDC;
  border-radius: 48px;
  border: 1.5px solid #DADBDC;
}

.layer {
  width: 100%;
  transition: 0.3s ease all;
  z-index: 1;
}

#button-13 .knobs:before,
#button-13 .knobs:after,
#button-13 .knobs span {
  position: absolute;
  top: 1.5px;
  width:13px;
  height: 12px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  border-radius: 2px;
  transition: 0.3s ease all;
}
#button-13 .knobs span {
	top:1.5px
}

#button-13 .knobs:before,
#button-13 .knobs:after {
  color: #4e4e4e;
  z-index: 1;
}

#button-13 .knobs:before {
  content: "";
  left: 7px;
  visibility: hidden;
  display: none;
  top: 5px;
}
#button-13 .knobs:after{
content: "";
}

#button-13 .knobs span {
  width: 16px;
  height: 16px;
  left: 2px;
  background-color: #fff;
  z-index: 2;
  border-radius: 100%;
}

#button-13 .checkbox:checked + .knobs span {
  left: 15px;
  background-color: #fff;
}
#button-13 .checkbox:checked + .knobs:before {
	content: '';
  visibility: visible;
  display: block;
  color: #fff;
}

#button-13 .checkbox:checked + .knobs {
 background-color: #003CFF;
 border: 1.5px solid #003CFF;
}
#button-13 .checkbox:checked + .knobs:after {
  visibility: hidden;
  display: none;
}

#button-13 .checkbox~ .layer {
  background-color: #fff;
  border-radius:48px ;
}

`;
import React, { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { toggleHorizontalState } from 'store/slices/AddPanelModalSlice/addPanelModalSlice';
import { ORIENTATION } from 'store/slices/AddPanelModalSlice/types';
import { PanelOrientationWrap, LabelOrientation, ModalToggle, LabelWithSwitch } from './styles';
import { setAddMultiplePanel } from 'store/slices/PanelSlice';

export default function SwitchOrientation() {
	const { orientation } = useSelector((state: RootState) => state.addPanelModal.data);
	const { enableMultipleAddPanel } = 	useSelector((state: RootState) => state.panelSlice.data);
	const dispatch = useDispatch<AppDispatch>();
	const handleEnableMultipanel = () => {
		dispatch(setAddMultiplePanel({shouldEnable:!enableMultipleAddPanel}));
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const changedVal = e.target.value;
		switch (changedVal) {
		case ORIENTATION.DEFAULT:
			dispatch(toggleHorizontalState({ value: changedVal }));
			break;
		case ORIENTATION.LANDSCAPE:
			dispatch(toggleHorizontalState({ value: changedVal }));
			break;
		case ORIENTATION.PORTRAIT:
			dispatch(toggleHorizontalState({ value: changedVal }));
			break;
		}
	};
	return (
		<PanelOrientationWrap>
			<LabelOrientation
				className={orientation == ORIENTATION.DEFAULT ? 'active' : ''}
				id="orientation-default"
				title="Default"
			>
				<input
					type="radio"
					value={ORIENTATION.DEFAULT}
					checked={orientation == ORIENTATION.DEFAULT}
					onChange={handleChange}
				/>
				<svg width="21" height="20" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect x="1" y="3.49146" width="9.9435" height="17.8983" 
						stroke={orientation == ORIENTATION.DEFAULT ? '#003CFF' : '#64686A'} strokeWidth="1.59096" />
					<rect x="1" y="3.49146" width="9.9435" height="17.8983" 
						stroke={orientation == ORIENTATION.DEFAULT ? '#003CFF' : '#64686A'} strokeWidth="1.59096" />
					<path d="M13.2119 11.5085L23 11.5085L23 21.452L13.2119 21.452" 
						stroke={orientation == ORIENTATION.DEFAULT ? '#003CFF' : '#64686A'} strokeWidth="1.59096" strokeLinecap="round" />
					<path d="M13.2119 11.5085L23 11.5085L23 21.452L13.2119 21.452" 
						stroke={orientation == ORIENTATION.DEFAULT ? '#003CFF' : '#64686A'} strokeWidth="1.59096" strokeLinecap="round" />
					<path d="M21.5 9.5C21.5 4 19 3.02542 13.3984 3.02542M21.5 9.5L23 8M21.5 9.5L20 8.5M13.3984 3.02542L14.6103 1.5M13.3984 
					3.02542L14.6103 4.70339" stroke={orientation == ORIENTATION.DEFAULT ? '#003CFF' : '#64686A'} 
					strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M21.5 9.5C21.5 4 19 3.02542 13.3984 3.02542M21.5 9.5L23 8M21.5 9.5L20 8.5M13.3984 3.02542L14.6103 
					1.5M13.3984 3.02542L14.6103 4.70339" stroke={orientation == ORIENTATION.DEFAULT ? '#003CFF' : '#64686A'} 
					strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
				</svg>
			</LabelOrientation>
			<LabelOrientation
				className={`label-orientaion ${ORIENTATION.LANDSCAPE ? 'active' : ''}`}
				id="orientation-landscape"
				// onClick={ORIENTATION.DEFAULT}
				title="Landscape"
			>
				<svg width="18" height="16" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M18.6233 15.5C16.6233 15.5 11.125 15.4999 2 15.4999M18.6233 15.5L17.1233 17M18.6233 15.5L17.1233 13.9999M2 
					15.4999L3.49998 13.9999M2 15.4999L3.49998 16.9999" stroke={orientation == ORIENTATION.LANDSCAPE ? '#003CFF' : '#64686A'}  
					strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
					<rect x="19" y="1" width="10" height="18" transform="rotate(90 19 1)" 
						stroke={orientation == ORIENTATION.LANDSCAPE ? '#003CFF' : '#64686A'} strokeWidth="1.6" />
				</svg>
				<input
					type="radio"
					value={ORIENTATION.LANDSCAPE}
					checked={orientation == ORIENTATION.LANDSCAPE}
					onChange={handleChange}
				/>
			</LabelOrientation>
			<LabelOrientation className={`label-orientaion ${orientation == ORIENTATION.PORTRAIT ? 'active' : ''}`}
				style={{
					borderRight: 'none',
					paddingRight: '6px',
				}}
				title="Portrait"
				id="orientation-portrait">
				<svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect x="8" y="1" width="11" height="18" stroke={orientation == ORIENTATION.PORTRAIT ? '#003CFF' : '#64686A'} strokeWidth="1.6" />
					<path d="M2.50005 18.6233C2.49997 16.6233 2.5001 11.125 2.50012 2M2.50005 18.6233L1 17.1233M2.50005 
					18.6233L4.0001 17.1233M2.50012 2L4.0001 3.49998M2.50012 2L1.00014 3.49998" 
					stroke={orientation == ORIENTATION.PORTRAIT ? '#003CFF' : '#64686A'} 
					strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
				</svg>
				<input
					type="radio"
					value={ORIENTATION.PORTRAIT}
					checked={orientation == ORIENTATION.PORTRAIT}
					onChange={handleChange}
				/>
			</LabelOrientation>
			<LabelWithSwitch>
				<LabelOrientation
					onClick={handleEnableMultipanel}
					className={enableMultipleAddPanel ? 'active' : ''}
					style={{
						gap:'.438rem',
						paddingLeft: '10px',
						borderLeft: '1px solid #E4E4E4',
						borderRight:'none',

					}}
					id="multiple-panel"
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="25" height="15" viewBox="0 0 25 15" fill="none">
						<path d="M22.6981 6.84127L24 14.1429H16.6226M22.6981 6.84127L21.6566 1H15.6679M22.6981 6.84127H2.3501M2.3501 
						6.84127L3.43019 1H9.59245M2.3501 6.84127L1 14.1429H8.76792M15.6679 1L16.6226 14.1429M15.6679 1H9.59245M16.6226 
						14.1429H8.76792M9.59245 1L8.76792 14.1429" 
						stroke={orientation == ORIENTATION.PORTRAIT ? '#003CFF' : '#64686A'} strokeWidth="1.6" />
						<path d="M22.6981 6.84127L24 14.1429H16.6226M22.6981 6.84127L21.6566 1H15.6679M22.6981 6.84127H2.3501M2.3501 
						6.84127L3.43019 1H9.59245M2.3501 6.84127L1 14.1429H8.76792M15.6679 1L16.6226 14.1429M15.6679 1H9.59245M16.6226 
						14.1429H8.76792M9.59245 1L8.76792 14.1429" 
						stroke={orientation == ORIENTATION.PORTRAIT ? '#003CFF' : '#64686A'} strokeWidth="1.6" />
					</svg>
					<span style={{
						color : 'rgb(74, 82, 90)',
						fontSize: '.813rem',
						whiteSpace: 'nowrap',
						fontFamily: 'Inter',
						fontWeight: '600',
					}}>Multi Panel</span>
				</LabelOrientation>
				<ModalToggle>
					<div className='switchButton bg-transparent'>
						<div className="toggle-button-cover">
							<div className="button-cover">
								<div className="button b2" id="button-13">
									<input
										checked={enableMultipleAddPanel}
										type="checkbox"
										className="checkbox"
										onChange={handleEnableMultipanel}
										// disabled={diableEditToggle}
									/>
									<div className="knobs">
										<span></span>
									</div>
									<div className="layer"></div>
								</div>
							</div>
						</div>
					</div>
				</ModalToggle>
			</LabelWithSwitch>
			
		</PanelOrientationWrap>
	);
}
import Button from 'components/Basic/Button';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { toggleOrientationModalState } from 'store/slices/ChangeOrientationModalSlice';
import { toggleDeleteModalState } from 'store/slices/DeleteModalSlice';
import { toggleShadingModalState } from 'store/slices/EditFacetModalSlice';
import { EditToolOptionsWrap } from '../styles';
import DeleteObstructionModeToggle from './DeleteObstructionBtn';
import RecalculateLayoutButton from './RecalculateLayoutBtn';
import { DESIGN_MODES } from '../../../../../constants';
import { useRefs } from 'contexts/RefContext';
import { getExistingPanelsGroupID } from 'components/tool/utils';

export default function EditToolOptions() {

	const { selectedRoofIndex, drawModeEnabled, newlyCreatedFacets, deletedRoofIndexes, roofIndexes, currentActiveFacetEditMode, hadTransition } =
		useSelector((state: RootState) => state.toolNewPostions.data);
	const { allRoofSegs, mode } = useSelector((state: RootState) => state.roofData.data);
	const { activePanelMode } = useSelector((state: RootState) => state.panelSlice.data);
	const { canRemoveShading } = useSelector((state: RootState) => state.QueryParams.data);

	const { konvaRef } = useRefs();
	const dispatch = useDispatch<AppDispatch>();

	const queriesRunningInBackGround = useQueriesRunningInBackGround();
	const changeOrientationQueryRunning = useQueriesRunningInBackGround(['changeFacetOrientation'], 'mutating');
	const removeShadingQueryInProgress = useQueriesRunningInBackGround(['remove-shading'], 'mutating');

	const isSelectedRoofIndexValid = !!selectedRoofIndex && !deletedRoofIndexes.includes(selectedRoofIndex);
	// facet opteration includes: delte, changeOriention, removeshading
	const isFacetOperationDisabled = activePanelMode || !!currentActiveFacetEditMode || !isSelectedRoofIndexValid || queriesRunningInBackGround;

	const hasUserDefinedFacets = Object.keys(newlyCreatedFacets).length;
	const disableDeleteBtnWhenNoUserDefinedFacet = drawModeEnabled && !hasUserDefinedFacets;
	const disableDeleteFacet = isFacetOperationDisabled || disableDeleteBtnWhenNoUserDefinedFacet;

	// diable remove shading, changeorientation when no panels in facet;
	const hasSelectedFacetPanels = !!selectedRoofIndex && !!konvaRef?.current?.findOne(`#${getExistingPanelsGroupID(selectedRoofIndex)}`)?.hasChildren();

	const disableRotateWhenFacetTranformed = (roofIndexes.length > 0);
	const disableChangeOrientationBtn = isFacetOperationDisabled || !hasSelectedFacetPanels || disableRotateWhenFacetTranformed;

	const facet = allRoofSegs.find(rs => rs.id === selectedRoofIndex);
	const isDefaultMode = mode === DESIGN_MODES.DEFAULT;
	const isGroundMount = !!facet?.isGroundMount;
	const canRemoveShadingEnabled = isDefaultMode && canRemoveShading && !isGroundMount;
	const disableRemoveShading = !hasSelectedFacetPanels || hadTransition;

	return (
		<EditToolOptionsWrap>
			<>
				<Button
					id="change-orientation"
					className="btn-tools"
					style={{
						background: '#fff',
					}} onClick={() => {
						dispatch(toggleOrientationModalState({ open: true, isLoading: false }));
					}}
					disabled={disableChangeOrientationBtn}
					showLoader={changeOrientationQueryRunning}
					tooltipContent='Change Orientation'
				>
					<svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M23 9.97489C23 5.92469 19.7407 2.24268 15.0329 2.24268L17.2058 4.82008M1 14.3933C1 18.4435 4.25926 22.1255 8.96708 22.1255L6.79424 19.5481M8.2428 1L22.6379 15.636L15.3951 23L1 8.36402L8.2428 1Z" stroke="#64686A" strokeWidth="1.69284" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
				</Button>
			</>
			{ (canRemoveShadingEnabled) && <>
				<Button
					id={!facet?.isFluxPatched ? 'remove-shading' : 'undo-remove-shading'}
					className="btn-tools"
					style={{
						background: '#fff',
					}}
					disabled={disableRemoveShading}
					onClick={() => {
						dispatch(toggleShadingModalState({ value: true , type: `${!facet?.isFluxPatched ? 'remove' : 'undo'}`}));
					}}
					tooltipContent={!facet?.isFluxPatched ? 'Remove shading' : 'Undo Remove shading'}
					showLoader={removeShadingQueryInProgress}
				>
					{!facet?.isFluxPatched ? 
						<svg width="19" height="23.75" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M17.0642 12.6377C17.9258 17.5208 12.4683 19.5315 10.0268 16.5154C6.00538 19.9623 1.98402 16.3718 2.84584 12.6377C1.12845 10.7771 0.628835 8.48752 1.26624 6.74929C1.90804 4.99908 3.70019 3.80582 5.86186 3.58966C7.58543 0.429882 11.6068 -0.144401 14.1918 3.58966C16.0904 3.43788 18.1401 5.06983 18.7878 7.18035C19.365 9.06129 18.7563 11.3519 17.0642 12.6377Z" stroke="#64686A" strokeWidth="1.69284" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M10.0288 7.03809V11.6339M10.0288 23.4108V14.6499M10.0288 11.6339L13.332 9.62324M10.0288 11.6339V14.6499M10.0288 14.6499L6.86914 12.7829" stroke="#64686A" strokeWidth="1.69284" strokeLinecap="round" strokeLinejoin="round"/>
						</svg>
						:
						<svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M14.6516 10.8899C15.3838 15.0396 10.7459 16.7483 8.67114 14.1852C5.25364 17.1144 1.83623 14.0632 2.56862 10.8899C1.10916 9.30868 0.684579 7.363 1.22626 5.88582C1.77166 4.39847 3.29466 3.38443 5.13168 3.20073C6.59639 0.515506 10.0138 0.027472 12.2106 3.20073C13.824 3.07174 15.5659 4.4586 16.1163 6.25215C16.6069 7.85059 16.0895 9.79718 14.6516 10.8899Z" stroke="#64686A" strokeWidth="1.69284" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M8.67046 6.13123V10.0368M8.67046 20.045V12.5999M8.67046 10.0368L11.4776 8.32813M8.67046 10.0368V12.5999M8.67046 12.5999L5.98535 11.0132" stroke="#64686A" strokeWidth="1.69284" strokeLinecap="round" strokeLinejoin="round"/>
						</svg>}
				</Button>
			</>}
			<>
				<Button
					id="delete"
					className="btn-tools"
					// title='Delete'
					style={{
						background: '#fff',
					}} onClick={() => {
						dispatch(toggleDeleteModalState({ value: true }));
					}}
					disabled={disableDeleteFacet}
					tooltipContent='Delete Facet'>
					<svg width="21" height="22.91" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M8.5 10.7778V18.1111M13.5 10.7778V18.1111M1 5.88889H21M19.75 5.88889L18.6662 20.7291C18.6214 21.3458 18.3391 21.923 17.8764 22.3444C17.4137 22.7657 16.8048 23 16.1725 23H5.8275C5.19517 23 4.58633 22.7657 4.12361 22.3444C3.66088 21.923 3.37865 21.3458 3.33375 20.7291L2.25 5.88889H19.75ZM14.75 5.88889V2.22222C14.75 1.89807 14.6183 1.58719 14.3839 1.35798C14.1495 1.12877 13.8315 1 13.5 1H8.5C8.16848 1 7.85054 1.12877 7.61612 1.35798C7.3817 1.58719 7.25 1.89807 7.25 2.22222V5.88889H14.75Z" stroke="#64686A" strokeWidth="1.60573" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
				</Button>
				<RecalculateLayoutButton/>
				<DeleteObstructionModeToggle/>
			</>
		</EditToolOptionsWrap>

	);
}
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay, { AutoplayOptionsType } from 'embla-carousel-autoplay';
import { Dot, DotsContainer, Slide, SlideContainer, SlideSection, SlideViewport } from './style';
import { useCallback, useEffect, useState } from 'react';
import { EmblaCarouselType } from 'embla-carousel/components/EmblaCarousel';
import { EmblaOptionsType } from 'embla-carousel';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const options: EmblaOptionsType = {
	align: 'start',
	dragFree: true,
	loop: true,
	slidesToScroll: 'auto',
};

const autoPlayPluginOptions: AutoplayOptionsType = {
	delay: 2000,
};

export default function Badges() {
	const [selectedIndex, setSelectedIndex] = useState(0);
	const { org } = useSelector((state: RootState) => state.roofData.data);

	const badges = useSelector((state: RootState) => state.roofData.data.org.configurations?.badges) ?? [];


	const [emblaRef, emblaApi] = useEmblaCarousel(options, [Autoplay(autoPlayPluginOptions)]);
	const scrollSnapList = emblaApi?.scrollSnapList() || [];

	const onSelect = useCallback((emblaApi: EmblaCarouselType) => {

		setSelectedIndex(emblaApi.selectedScrollSnap());
	}, []);

	useEffect(() => {
		if (!emblaApi) return;
		onSelect(emblaApi);
		emblaApi.on('reInit', onSelect).on('select', onSelect);
	}, [emblaApi, onSelect]);

	if (!badges.length) return null;

	return (
		<SlideSection className={org.setting.isB2C ?'b2cView':''}>
			<SlideViewport ref={emblaRef}>
				<SlideContainer className={scrollSnapList.length === 1 ? 'center' : 'start'}>
					{badges.map((url: string, index: number) => (<Slide className={org.setting.isB2C ?'b2c':''} key={index} src={url} />))}
				</SlideContainer>
			</SlideViewport>

			{scrollSnapList.length > 1 &&
				<DotsContainer>
					{scrollSnapList.map((_, index) => (
						<Dot
							key={index}
							selected={index === selectedIndex}
						/>
					))}
				</DotsContainer>
			}
		</SlideSection>

	);
}

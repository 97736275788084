import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { Group, Line, Rect } from 'react-konva';

import { useRefs } from 'contexts/RefContext';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { RectConfig } from 'konva/lib/shapes/Rect';
import { doesPolygonOverLap, generatePolygon, isCompletelyInsideFacet } from '../PanelUtil';
import { setAddMultiplePanel, setDeletedPanels, setNewlyAddedPanel } from 'store/slices/PanelSlice';
import { KONVA_FACET_GROUP, SEMI_TRANPARENT_BLACK_PANEL } from '../../../constants';
import { ORIENTATION } from 'store/slices/AddPanelModalSlice/types';
import Konva from 'konva';
import { KonvaGroupNames } from '../utils';
import { useKonvaImageScale } from 'contexts/ToolDimensionsAndScaleContext';

const CustomRect = () => {
	const { konvaRef: stageRef, movablePanelRef } = useRefs();

	const scale = useKonvaImageScale();

	const { editModeEnabled, moveStage } = useSelector((state: RootState) => state.toolNewPostions.data);
	const { allRoofSegs } = useSelector((state: RootState) => state.roofData.data);

	const [startPoint, setStartPoint] = useState<null | { x: number; y: number; width: number; height: number }>(null);
	const [rectDims, setRectDims] = useState<null | { width: number, height:number }>(null);

	const targetFacetRef = useRef<RasterRoofSegment | null>(null);

	const { orientation: panelOrientation } = useSelector((state: RootState) => state.addPanelModal.data);
	const { enableMultipleAddPanel, deleteIndividualPanelMode, enableAddPanel } = useSelector((state: RootState) => state.panelSlice.data);
	const [commonAngle, setCommonAngle] = useState<any>(null);
	const [facetPolygon, setFacetPolygon] = useState<number[]>([]);

	const dispatch = useDispatch();

	const smallRects = useMemo(() => {
		const smallRectsAsLines:any = [];
		const targetFacet = targetFacetRef.current;
		if(!targetFacet || !startPoint || !stageRef?.current || !rectDims || facetPolygon.length < 1  || deleteIndividualPanelMode) {
			return smallRectsAsLines;
		}
		const smallWidth = targetFacet.panelWidth  * scale.x;
		const smallHeight = targetFacet.panelHeight  * Math.cos(targetFacet.pitchDegrees * (Math.PI / 180)) *  scale.y;

		const angleInRadians = (commonAngle * Math.PI) / 180;
		const prebuildLineRects = stageRef.current.find(`.panel-${targetFacet.id}`);

		if (!rectDims.width || !rectDims.height) return [];

		const cols = Math.floor(Math.abs(rectDims?.width) / smallWidth);
		const rows = Math.floor(Math.abs(rectDims?.height) / smallHeight);
		
		const rowVector = Math.abs(rectDims.width) / rectDims.width;
		const coVector = Math.abs(rectDims.height) / rectDims.height;
		
		const rowConstant = rowVector > 0 ? 0 : 1;
		const colConstant = coVector > 0 ? 0 : 1;

		for (let row = 0; row < rows; row++) {
			for (let col = 0; col < cols; col++) {
				const localX = (col + rowConstant) * smallWidth * rowVector;
				const localY = (row + colConstant) * smallHeight * coVector;
				
				const points = [
					{ x: localX, y: localY },
					{ x: localX + smallWidth, y: localY },
					{ x: localX + smallWidth, y: localY + smallHeight },
					{ x: localX, y: localY + smallHeight },
				];
				
				const transformedPoints = points.map((point) => {
					const rotatedX = point.x * Math.cos(angleInRadians) - point.y * Math.sin(angleInRadians);
					const rotatedY = point.x * Math.sin(angleInRadians) + point.y * Math.cos(angleInRadians);
					
					const stageX = startPoint.x + rotatedX;
					const stageY = startPoint.y + rotatedY;
					
					return { x: stageX, y: stageY };
				});
				
				const flatPoints = [
					transformedPoints[0].x,
					transformedPoints[0].y,
					transformedPoints[1].x,
					transformedPoints[1].y,
					transformedPoints[2].x,
					transformedPoints[2].y,
					transformedPoints[3].x,
					transformedPoints[3].y,
					transformedPoints[0].x,
					transformedPoints[0].y,
				];
				
				const smallRectPolygon = generatePolygon(flatPoints);
				
				const hasCollision = prebuildLineRects.some(eachPanel => {
					const convertedPolygon = generatePolygon(eachPanel.attrs.points);
					return doesPolygonOverLap(smallRectPolygon, convertedPolygon);
				});

				const isInsideFacet = isCompletelyInsideFacet(flatPoints, facetPolygon);

				if(isInsideFacet && !hasCollision) {
					smallRectsAsLines.push(flatPoints);
				}
			}
		}
		
		return smallRectsAsLines;
	}, [startPoint, stageRef, scale, commonAngle, rectDims, deleteIndividualPanelMode]);

	const deletedRect = useMemo(() => {
		const smallRectsAsLines:any = [];
		if(!deleteIndividualPanelMode || !startPoint || !stageRef?.current || !rectDims) {
			return smallRectsAsLines;
		}

		const rectPoints = [
			{ x: startPoint.x, y: startPoint.y },
			{ x: startPoint.x + rectDims.width, y: startPoint.y },
			{ x: startPoint.x + rectDims.width, y: startPoint.y + rectDims.height },
			{ x: startPoint.x, y: startPoint.y + rectDims.height },
		];

		const angleDegrees = commonAngle;
		const angleRadians = (angleDegrees * Math.PI) / 180;
	
		const transformedPoints = rectPoints.map((point) => {
			const rotatedX = startPoint.x + (point.x - startPoint.x) * Math.cos(angleRadians) - (point.y - startPoint.y) * Math.sin(angleRadians);
			const rotatedY = startPoint.y + (point.x - startPoint.x) * Math.sin(angleRadians) + (point.y - startPoint.y) * Math.cos(angleRadians);
			return { x: rotatedX, y: rotatedY };
		});

		const flatRectPoints = transformedPoints.flatMap((point) => [point.x, point.y]);

		const prebuildLineRects = stageRef.current
			.find('Line')
			.filter((line) => line.name().startsWith('panel-'));

		return prebuildLineRects.reduce((acc:any, eachPanel: any) => {
			const convertedPolygon = generatePolygon(eachPanel.attrs.points);
			const rectPolygon = generatePolygon(flatRectPoints);
			const isIntersecting = doesPolygonOverLap(rectPolygon, convertedPolygon);
			if(isIntersecting) {
				acc.push({panelId:eachPanel.attrs.id, roofSegmentId: eachPanel.attrs.name.replace('panel-','') });
				eachPanel.fill('rgba(0, 128, 255, 0.5)');
			} else {				
				eachPanel.fill(SEMI_TRANPARENT_BLACK_PANEL);
			}
			return acc;
		}, []);
	}, [startPoint, stageRef, scale, commonAngle, rectDims, deleteIndividualPanelMode]);

	const handlePointerDown = useCallback(() => {
		const stage = stageRef?.current;
		if(!(enableAddPanel || deleteIndividualPanelMode) || !stage || moveStage ) return;

		const position = stage.getRelativePointerPosition();
		const pointerPosition = stage.getPointerPosition();
		
		if(deleteIndividualPanelMode) {
			const width = 0;
			const height = 0;
			setCommonAngle(0);
			setRectDims({width, height});
			setStartPoint({x: position.x, y: position.y, width, height });
			return;
		}
		
		const prevousTargetFacet = stage.findOne(`#${targetFacetRef.current?.id}`) as Konva.Line;
		if (!position || !pointerPosition) return;
		const targetFacetPolygon = stage.getAllIntersections(pointerPosition).find((node)=>node.hasName(`${KonvaGroupNames.existingFacet}`)) ?? prevousTargetFacet;

		if (enableMultipleAddPanel && targetFacetPolygon) {
			
			let matchingRoofSeg = allRoofSegs.find((seg:any) => seg.id === targetFacetPolygon.id());
			if(targetFacetRef.current && targetFacetPolygon.id !== targetFacetRef.current.id) {
				matchingRoofSeg = targetFacetRef.current;
			}
			if(matchingRoofSeg) {
				const currentCoords = matchingRoofSeg.setbackPolygonCoords.length > 0 ? matchingRoofSeg.setbackPolygonCoords : matchingRoofSeg.simplifiedHullCoords;
				setFacetPolygon(
					currentCoords
						.map(coordPair => {
							let [x, y ] = coordPair;
							x *= scale.x;
							y *= scale.y;
							return [x,y];
						})
						.flat());
				const width =  matchingRoofSeg.panelWidth  *  scale.x;
				const height =  matchingRoofSeg.panelHeight  * Math.cos(matchingRoofSeg.pitchDegrees * (Math.PI / 180)) *  scale.y;

				setStartPoint({x: position.x, y: position.y, width: width, height: height });
				
				let rotationAngle = matchingRoofSeg.azimuthDegrees;
				const chnageOrientation = (matchingRoofSeg.orientation === 'POTRAITT' && panelOrientation === ORIENTATION.LANDSCAPE) ||
				(matchingRoofSeg.orientation === 'LANDSCAPE' && panelOrientation === ORIENTATION.PORTRAIT);

				if (chnageOrientation) {
					rotationAngle+=90;
				}
				rotationAngle %= 360;
				setCommonAngle(rotationAngle);
			}
		} else {
			const position = stage.getPointerPosition();
			const stageScale = stage.scale() as Konva.Vector2d;
			if(!position) return;
			
			const adjustedPointerPosition = {
				x: (position.x - stage.x()) / stageScale.x + stage.offsetX(),
				y: (position.y - stage.y()) / stageScale.y + stage.offsetY(),
			};
			movablePanelRef?.current?.visible(true);
			movablePanelRef?.current?.position(adjustedPointerPosition);
			movablePanelRef?.current?.startDrag();
		}
	},[allRoofSegs, commonAngle, dispatch, enableMultipleAddPanel, enableAddPanel, movablePanelRef, moveStage, panelOrientation, scale.x, scale.y, stageRef, deleteIndividualPanelMode]);

	const handlePointerMove = useCallback(() => {
		if (!stageRef?.current) return;

		const stage = stageRef.current;
		const position = stage.getRelativePointerPosition();

		if (!position || !startPoint || !startPoint.x || !startPoint.y || !(targetFacetRef.current || deleteIndividualPanelMode)) return;
		// dy<0: south, dy>0: north
		//dx<0: west, dx>0: east

		const dx = position.x - startPoint.x;
		const dy = position.y - startPoint.y;

		const angleDegrees = commonAngle;
		const angleRadians = (angleDegrees * Math.PI) / 180;
		
		let projectedWidth = startPoint.width;
		let projectedHeight = startPoint.height;

		projectedWidth = dx * Math.cos(angleRadians) + dy * Math.sin(angleRadians);
		projectedHeight = -dx * Math.sin(angleRadians) + dy * Math.cos(angleRadians);

		
		setRectDims({
			width: projectedWidth,
			height: projectedHeight
		});
	},[commonAngle, stageRef, startPoint]);

	const handlePointerUp = useCallback(() => {
		if(!rectDims) {
			const stage = stageRef?.current;
			if(!(enableAddPanel || deleteIndividualPanelMode) || !stage || moveStage ) return;
			
			const prevousTargetFacet = stage.findOne(`#${targetFacetRef.current?.id}`) as Konva.Line;
			prevousTargetFacet?.fill('transparent');
			const position = stage.getRelativePointerPosition();
			const pointerPosition = stage.getPointerPosition();
			if (!position || !pointerPosition) return;
	
			const targetFacetPolygon = stage.getAllIntersections(pointerPosition).find((node)=>node.hasName(`${KonvaGroupNames.existingFacet}`)) ?? prevousTargetFacet;
			if (targetFacetPolygon) {
				targetFacetPolygon?.fill('rgba(255, 255, 0, 0.3)');
			}
			const matchingRoofSeg = allRoofSegs.find((seg:any) => seg.id === targetFacetPolygon?.id());
			if(matchingRoofSeg) {
				targetFacetRef.current = matchingRoofSeg;
			}
			setStartPoint(null);
			setCommonAngle(null);
			setFacetPolygon([]);
			return;
		}
		if(deleteIndividualPanelMode && deletedRect.length > 0) {
			dispatch(setDeletedPanels({data: deletedRect}));
		}
		const newlyAddedPanels = smallRects.map((p: number[]) => {
			const exteriorCoords = [];
			for (let i = 0; i < p.length - 1; i += 2) {
				exteriorCoords.push([p[i] / scale.x, p[i + 1] / scale.y]);
			}
			return {
				exteriorCoords,
				orientation: panelOrientation,
				roofSegmentId: targetFacetRef.current?.id,
			};
		});
		setStartPoint(null);
		setRectDims(null);
		setCommonAngle(null);
		setFacetPolygon([]);

		if(enableMultipleAddPanel && newlyAddedPanels.length > 0) {
			dispatch(setNewlyAddedPanel({addedPanels: newlyAddedPanels}));
		}


		
		if (movablePanelRef?.current) {
			movablePanelRef.current.stopDrag();
		}
	},[dispatch, enableMultipleAddPanel, movablePanelRef, panelOrientation, scale.x, scale.y, smallRects, deleteIndividualPanelMode]);

	useEffect(() => {
		if (!stageRef?.current || moveStage) return;
		
		const stage = stageRef.current;

		stage.on('pointerdown', handlePointerDown);
		stage.on('pointermove', handlePointerMove);
		stage.on('pointerup', handlePointerUp);

		return () => {
			if(targetFacetRef.current && !enableMultipleAddPanel) {
				const prevousTargetFacet = stage.findOne(`#${targetFacetRef.current?.id}`) as Konva.Line;
				prevousTargetFacet?.fill('transparent');
				targetFacetRef.current = null;
			}
			stage.off('pointerdown', handlePointerDown);
			stage.off('pointermove', handlePointerMove);
			stage.off('pointerup', handlePointerUp);
		};
	}, [stageRef, handlePointerDown, handlePointerMove, handlePointerUp, enableAddPanel, editModeEnabled, panelOrientation, moveStage, deleteIndividualPanelMode, enableMultipleAddPanel]);

	return (
		<>
			<Group
				x={startPoint?.x || 0}
				y={startPoint?.y || 0}
				rotation={commonAngle}
				id={`${targetFacetRef.current?.id}${KONVA_FACET_GROUP.IN_PROGRESS_GROUP_NAME}${KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR}panels`}
				name={`${KONVA_FACET_GROUP.IN_PROGRESS_GROUP_NAME}${KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR}panels`}
			>
				{/* Main Rectangle */}
				<Rect
					width={rectDims?.width}
					height={rectDims?.height}
					stroke="black"
					strokeWidth={1}
				/>
			</Group>
			{smallRects.map((points:any, index:any) => (
				<Line
					key={index}
					points={points}
					stroke={'rgba(0, 128, 255, 0.5)'}
					strokeWidth={1.4}
					closed={true}
				/>
			))}
		</>
	);
};

export default CustomRect;

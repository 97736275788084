import loader from './loader2.gif';
import { LoaderDiv, PulseDot, PulseLoaderDiv } from './styles';
import LoadingSlider from './slider';
import { Interpolation } from 'styled-components';
import { useEffect, useRef, useState } from 'react';

export enum Loader_Type {
	SLIDER = 'slider',
	INDEFINITE = 'indefinite',
	DEFAULT = 'default'
}

type Props = {
	loaderText?: string;
	type?: Loader_Type
	styles?: Interpolation<React.CSSProperties>;
};

const ProgressStages = [
	{ text: 'Downloading imagery and lidar data', time: 5 },
	{ text: 'Processing the roof data', time: 5 },
	{ text: 'Creating facets and obstructions', time: 5 },
	{ text: 'Generating solar panel layout', time: 10 },
	{ text: 'Estimating energy production', time: 10 },
	{ text: 'This is taking longer than usual. Please wait for some time..', time: Infinity }
];

function IndefiniteLoader() {

	const [currentStep, setCurrentStep] = useState<number>(0);
	const stepIndexRef = useRef<number>(0);

	useEffect(() => {
		let timeoutId: NodeJS.Timeout;
		const updateStep = () => {
			if (stepIndexRef.current < ProgressStages.length - 1) {
				setCurrentStep(stepIndexRef.current);
				timeoutId = setTimeout(updateStep, ProgressStages[stepIndexRef.current].time * 1000);
				stepIndexRef.current += 1;
			} else {
				setCurrentStep(stepIndexRef.current);
			}
		};

		updateStep();
		return () => {
			clearTimeout(timeoutId);
		};
	}, []);

	return (
		<LoaderDiv>
			<p className='loaderText'>This will take a few seconds</p>
			<img className='loaderImage' src={loader} />
			<h3 className='loaderHeading'>{ProgressStages[currentStep].text}</h3> 
			{/* <PulseLoaderDiv>
			
				<PulseDot delay="0s" />
				<PulseDot delay="0.2s" />
				<PulseDot delay="0.4s" />
			</PulseLoaderDiv> */}
		</LoaderDiv>
	);

}

export default function Loader(props: Props) {
	const { loaderText, styles, type = Loader_Type.DEFAULT } = props;

	if(type === Loader_Type.INDEFINITE){
		return <IndefiniteLoader/>;
	}

	return (
		<LoaderDiv styles={styles}>
			<img src={loader} />
			{loaderText && <>{loaderText}...</>}

			{type === Loader_Type.SLIDER && <LoadingSlider />}
		</LoaderDiv>
	);
}


import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { ModalBox, ModalBoxWrap } from './style';

export default function ModeStrip({children, classname= '' }: { children: React.ReactNode, classname?:string }) {

	const { fullScreenEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);

	return (
		<ModalBoxWrap className={`${fullScreenEnabled ? ' fullscreen' : ''} ${classname}`} >
			<ModalBox>
				{children}
			</ModalBox>
		</ModalBoxWrap>
	);
}
import { getAbsolutePoints } from 'components/DisplayEnergy/util';
import Konva from 'konva';
import { normalizeAngle } from 'components/tool/utils/util';
import { getRoofSegmentIDFromGroupID, KonvaGroupNames } from 'components/tool/utils';

export function getAbsolutePointsOfTranslatedPanels(stage: Konva.Stage, roofIndexes: string[],
	allRoofSegs: RasterRoofSegment[], currentScale: Vector2d
) {
	try {
		const groups = stage.find( `.${KonvaGroupNames.existingPanelsGroup}`)
			.filter(g => roofIndexes.includes(getRoofSegmentIDFromGroupID(g.attrs.id))) as Konva.Group[];
		const newPos: {
			segmentId: string,
			azimuth: number,
			previousAzimuthDegrees: number,
			translatedPos: {
				[id: string]: {
					pos: number[][]
				}
			};
			pitch?: number;
			shading?: number;
			previousShading?: number;
			previousPitchDegrees?: number;
		}[] = [];

		// const stagePosition = stage.position();
		groups?.forEach((g, i) => {
			const translatedPos: typeof newPos[0]['translatedPos'] = {};
			g.children?.forEach((child) => {
				if (child.className === 'Line') {
					const newPoints = (getAbsolutePoints(child as Konva.Line, currentScale, stage) as number[]);
					translatedPos[child.attrs.id] = {
						pos: [
							[newPoints[0], newPoints[1]],
							[newPoints[2], newPoints[3]],
							[newPoints[4], newPoints[5]],
							[newPoints[6], newPoints[7]],
							[newPoints[8], newPoints[9]]]
					};
				}
			});
			const segmentId = getRoofSegmentIDFromGroupID(g.attrs.id);
			const roofSegment = allRoofSegs.find(rs => rs.id === segmentId);
			const newPitch = g.getAttr('pitch');
			const shading = g.getAttr('shading');
			if (!roofSegment) return [];
			console.log(segmentId, g.attrs.rotation,g.getAttr('rotation'), normalizeAngle(g.rotation()-roofSegment.azimuthDegrees));

			newPos.push({
				segmentId,
				azimuth: g.attrs.rotation != null ? normalizeAngle(g.attrs.rotation - roofSegment.azimuthDegrees) : 0,
				previousAzimuthDegrees: roofSegment.azimuthDegrees,
				translatedPos,
				previousPitchDegrees: roofSegment.pitchDegrees,
				pitch: newPitch ?? roofSegment.pitchDegrees,
				shading: shading ?? roofSegment.shading,
				previousShading: roofSegment.shading,
			});
		});
		return newPos;
	} catch (e) {
		console.log('error', e);
		throw new Error('E_WHILE_GETTING_TRANSLATION_FOR_SEGMENT');
	}
}
import { RootState } from 'store';
import { NextStepperDiv, NextStepperDivTitleList, NextStepperDivTitle, NextStepperDivDesc } from './style';
import { useSelector } from 'react-redux';

interface NextStep {
	step: number;
	title: string;
	desc: string;
}

export default function NextSteps() {
	const { org } = useSelector((state: RootState) => state.roofData.data );
	const { showNextSteps } = useSelector((state: RootState) => state.QueryParams.data );
	const nextSteps = org.configurations?.nextSteps ?? org.setting?.dataForTool?.nextSteps ?? [];
	const showSteps = showNextSteps && !!nextSteps?.length;

	if (!showSteps) {
		return null;
	}

	return (
		<NextStepperDiv>
			<ul className="timeline">
				{nextSteps.map((nextStep: NextStep) => (
					<li className="event" key={nextStep.step}>
						<NextStepperDivTitleList className="member-infos">
							<NextStepperDivTitle className="stepperName">
								<span className="stepperCount">
									Step {nextStep.step}:
								</span>{' '}
								{nextStep.title}
							</NextStepperDivTitle>
							<NextStepperDivDesc className="stepperDesc">
								{nextStep.desc}
							</NextStepperDivDesc>
						</NextStepperDivTitleList>
					</li>
				))}
			</ul>
		</NextStepperDiv>
	);
}
import { useRef, useState, useCallback } from 'react';
import { KonvaEventObject } from 'konva/lib/Node';

interface UseLongPressOptions<T> {
	delay: number;
	enabled: boolean; 
	onLongPress: (position: { x: number; y: number }, pointIndex: number, segmentId: string) => void;
}

export const useLongPress = <T>({ delay = 2000, enabled = true, onLongPress }: UseLongPressOptions<T>) => {
	const longTouchTimerRef = useRef<NodeJS.Timeout | null>(null);

	const handleTouchStart = useCallback(
		(e: KonvaEventObject<TouchEvent>, customData?: any) => {
			if (!enabled) return;

			clearTimeout(longTouchTimerRef.current!);
			longTouchTimerRef.current = setTimeout(() => {
				if (onLongPress) {
					onLongPress({ x: e.evt.touches[0].clientX, y: e.evt.touches[0].clientY }, customData?.pointIndex, customData?.segmentId);
				}
			}, delay);
		},
		[enabled, delay, onLongPress]
	);

	const handleTouchEndOrCancel = useCallback(() => {
		clearTimeout(longTouchTimerRef.current!);
	}, []);

	return {
		handleTouchStart,
		handleTouchEndOrCancel,
	};
};

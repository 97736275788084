import { useSearchParams } from 'react-router-dom';
import { useQueries, useQuery } from 'react-query';
import { getDesignMetaDataByUUID, getLatLng, verifyPublicKey, verifyPanelKey, getDesignMetdaDataByExternalId, getThemeData } from './api';
import ROUTES from 'constants/routes';
import Loader, { Loader_Type } from 'components/Loader';
import { useEffect, useState } from 'react';
import { 
	DEFAULT_ELECTRIC_UNIT_PRICE, 
	ENERGY_DERATE_RATE, 
	SOlAR_QOUTE_APP_NAME, 
} from '../../constants';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { setAuth } from 'store/slices/AuthSlice/authSlice';
import { fetchRoofData, setOrgData } from 'store/slices/RoofDataSlice/roofDataSlice';
import { setQueryParamState } from 'store/slices/QueryParamsSlice';
import { QueryParamsFromParentApp } from 'store/slices/QueryParamsSlice/types';
import { validateLatLng } from './util';
import { decideDerateBasedUponMode } from '../../util';
import { useThemeDispatch } from 'contexts/ThemeContext';
import useNavigateWithQueryParams from 'hooks/useNavigateWithQueryParams';

export default function Home() {
	const [defaultPanelKey, setDefaultPanelKey] = useState<string>();
	const [error, setError] = useState('');
	// get query param from url
	// verify  it set auth
	// and lead to next page
	// ?publicKey='ee'?'externalKey'

	const dispatch = useDispatch<AppDispatch>();
	const themeDispatch= useThemeDispatch();
	const { navigateWithQueryParams } = useNavigateWithQueryParams();

	const [queryParams] = useSearchParams();

	const publicKey = queryParams.get('public-key');
	const externalId = queryParams.get('external-id');
	const billAmount = queryParams.get('bill-amount');
	const aiToolPanelKeys = queryParams.get('ai-tool-panel-keys');
	const address = queryParams.get('address') as string;
	const lat = queryParams.get('lat');
	const lng = queryParams.get('lng');
	const annualUsage = queryParams.get('annual-usage');
	const electricUnitPrice = queryParams.get('electric-unit-price');
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const panelKey = queryParams.get('panel-key')?.trim() ? decodeURIComponent(queryParams.get('panel-key')!) : undefined;
	const parentAppName = queryParams.get('parent-id') || '';
	const designUUID = queryParams.get('design-uuid');
	const showNextSteps = queryParams.get('show-steps');
	const energyDerateRate = queryParams.get('energy-derate-rate');
	const canRemoveShading = queryParams.get('can-remove-shading');
	const gutterSetback = queryParams.get('gutter_setback');
	const nonGutterSetback = queryParams.get('other_setback');
	const obstructionSetback = queryParams.get('obstruction_setback');
	const stateCode = queryParams.get('state-code');
	// TODO: update settings permissions flow
	const accessToSettings = queryParams.get('can-edit-setback');

	if (!externalId && !designUUID) {
		return <p>external id or designuuid is required</p>;
	}
	
	if (!publicKey) {
		return <p>public key is required</p>;
	}

	if (!billAmount || Number.isNaN(+billAmount) || +(billAmount) < 50 || +(billAmount) > 10001) {
		return <p> enter valid bill amount</p>;
	}

	if (!address && (!lat || !lng)) {
		return <p>address or lat,lng is required</p>;
	}

	if (!address) {
		return <p>address is required</p>;
	}

	if(lat && lng){
		if(!validateLatLng(lat,lng))
			return <p>lat, lng is not valid</p>;
	}

	if (electricUnitPrice !== null && (isNaN(+electricUnitPrice) || !(+electricUnitPrice))) {
		return <p>Invalid electric unit price</p>;
	}

	if (annualUsage !== null && (isNaN(+annualUsage) || !(+annualUsage))) {
		return <p>Invalid annual usage</p>;
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const publicKeyVerificationQuery = useQuery('verify', () => verifyPublicKey(publicKey), {
		onSuccess: (data: boolean) => {
			try {
				if (!data) return;
				sessionStorage.setItem('SOLAR_QUOTE_EMBED_Authorization', publicKey);
				dispatch(setAuth({ token: publicKey }));
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				setError('Please grant all required permissions and refresh the page to run the application correctly');
				console.log('errr', error);
			}
		},
		onError: (err) => {
			console.log('err', err);
		},
		refetchOnWindowFocus: false,
		cacheTime: 0,
		retry: 2
	});

	const onThemeDataSuccess = (data: organization) => {
		try {
			if (!data) return;
			themeDispatch({type: 'SET_THEME', payload: {settings: data.settings, parentAppName, config: data.configurations}});
			dispatch(setOrgData(data.settings));
			setDefaultPanelKey(defaultPanelKey);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			// setError('Please grant all required permissions and refresh the page to run the application correctly');
			console.log('errr', error);
		}
	};

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const themeData = useQuery('themeData', () => getThemeData(publicKey), {
		onSuccess: onThemeDataSuccess,
		onError: (err) => {
			console.log('err', err);
		},
		refetchOnWindowFocus: false,
		cacheTime: 0,
		retry: 2,
		// enabled:publicKeyVerificationQuery.data
	});
	// console.log(themeData);

	const shouldCallPanelKeyQuery = !!panelKey && publicKeyVerificationQuery.data && Boolean(themeData?.data);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const panelKeyExistsQuery = useQuery('panelKey', () => verifyPanelKey(panelKey as string, publicKey), {
		enabled: shouldCallPanelKeyQuery,
		refetchOnWindowFocus: false,
		cacheTime: 0,
		onSuccess: (data: boolean) => {
			console.log('panel key exists', data);
		}
	});

	const shouldCallAddressAndExternalIdQueries = panelKey ? !!panelKeyExistsQuery.data : !!publicKeyVerificationQuery.data;
	const shouldCallAddressQuery = !(lat && lng); 
	const queries = designUUID ? [
		{
			queryKey: ['designUUID', designUUID],
			queryFn: () => getDesignMetaDataByUUID(designUUID, publicKey),
			enabled: shouldCallAddressAndExternalIdQueries
		}] : [
		{
			queryKey: ['externalIdData', externalId],
			queryFn: () => getDesignMetdaDataByExternalId(externalId || '', publicKey),
			enabled: shouldCallAddressAndExternalIdQueries
		}];
	if(shouldCallAddressQuery)
		queries.push({
			queryKey: ['getAddress', address],
			queryFn: () => getLatLng(address, publicKey),
			enabled: shouldCallAddressAndExternalIdQueries
		});
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const addressAndDataQuery = useQueries(queries);

	const allFinished = addressAndDataQuery.every(query => query.isSuccess);
	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(() => {
		if (allFinished && !error) {
			const [designData, addressData] = addressAndDataQuery;
			if (designUUID && !designData.data) {
				setError(`uuid ${designUUID} has no data assoicated with it`);
				return;
			}
			const coordinates = shouldCallAddressQuery ? addressData.data : {lat: +lat,lng: +lng};

			const queryParmaObj: QueryParamsFromParentApp = {
				billAmount: +billAmount,
				designUUID: designData.data.designUUID,
				coordinates: coordinates,
				electricUnitPrice: +(Number(electricUnitPrice).toFixed(2) || DEFAULT_ELECTRIC_UNIT_PRICE),
				externalId: designData.data.externalId,
				panelKey,
				parentAppName,
				lastEnergyInputChange: annualUsage ? 'annualEnergy' : designData.data.lastEnergyInputChange,
				annualEnergy: +(annualUsage || designData.data.annualEnergy),
				energyDerateRate: energyDerateRate ? +energyDerateRate : ENERGY_DERATE_RATE,
				derateRateBeforeUserSwitchToNonDefaultModes: energyDerateRate ? +energyDerateRate : ENERGY_DERATE_RATE,
				showNextSteps: showNextSteps === 'true',
				canRemoveShading: canRemoveShading === 'true',
				gutterSetback: gutterSetback ? (+gutterSetback) : undefined,
				nonGutterSetback: nonGutterSetback ? (+nonGutterSetback) : undefined,
				obstructionSetback: obstructionSetback ? (+obstructionSetback): undefined,
				accessToSettings: accessToSettings === 'true',
				aiToolPanelKeys: aiToolPanelKeys?.length? aiToolPanelKeys.split(',') : undefined,
				stateCode: stateCode ?? undefined
			};
			let navigationRoute;
			if (designData.data) {
				let modeEnergyRate = energyDerateRate ? +energyDerateRate : ENERGY_DERATE_RATE;

				modeEnergyRate = decideDerateBasedUponMode(designData.data.mode, queryParmaObj.energyDerateRate, stateCode);
				queryParmaObj.derateRateBeforeUserSwitchToNonDefaultModes = queryParmaObj.energyDerateRate;
				queryParmaObj.energyDerateRate = modeEnergyRate;

				dispatch(setQueryParamState(queryParmaObj));
				const panelKeyForDesign = panelKey || defaultPanelKey;
				dispatch(fetchRoofData(
					{
						uuid: designData.data.designUUID as string,
						panelKey: panelKeyForDesign, queryParamBillAmount: +billAmount,
					}));
				window.parent.postMessage({ id: 'SOLARQUOTEEMBED_SHOW_DATA' }, '*');
				navigationRoute = ROUTES.SHOW_DATA;
			} else {
				queryParmaObj.externalId = externalId as string;
				dispatch(setQueryParamState(queryParmaObj));
				navigationRoute = ROUTES.SHOW_MAP;
			}
			navigateWithQueryParams(navigationRoute, {
				state: {
					coordinates: queryParmaObj.coordinates
				}
			});

		}
	}, [allFinished, navigateWithQueryParams]);

	if (error) {
		return <p>{error}</p>;
	}

	if (publicKeyVerificationQuery.isLoading || panelKeyExistsQuery.isLoading) {
		const type = parentAppName === SOlAR_QOUTE_APP_NAME ? Loader_Type.SLIDER : Loader_Type.DEFAULT;
		return <Loader type={type}/>;
	}

	if (!publicKeyVerificationQuery.data) {
		return <p>Unauthorised</p>;
	}

	if (panelKey && !panelKeyExistsQuery.data) {
		return <p>{`panel key ${panelKey} doesnot exist, please contact your organization admin.`}</p>;
	}

	return null;
}

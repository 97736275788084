import styled from 'styled-components';

const slideSizeLarge = '50%'; //of viewport(100)
const slideSizeSmall = '25%';
const slideMedium ='33.3%';
const slideSpace = '2rem';

const SlideSection = styled.section`
  margin-top: 2rem;
	width: 100%;
	overflow:hidden;
		@media (min-width: 1023px) and (max-width: 1399px) and (max-height: 900px)  {
		&.b2cView{
	margin-top: 0.5rem;
}
	
`;

const SlideViewport = styled.div`
	overflow: hidden;
`;

const Slide = styled.img`
	flex: 0 0 ${slideSizeLarge};
	
	transform: translate3d(0, 0, 0);
	padding-left: ${slideSpace};
	min-width: 0;
  max-width: 100%;
	width:100%;
	height:100%;
	@media (min-width:768px) {
		flex: 0 0 ${slideSizeLarge};
	}
	@media (min-width:1400px) {
		flex: 0 0 ${slideSizeSmall};
			&.b2c{
		flex: 0 0 ${slideSizeSmall};
}
	}
		@media (min-width: 1023px) and (max-width: 1399px) {
		&.b2c{
		flex: 0 0 ${slideMedium};
}
		
		}

		
`;

const SlideContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-left: -${slideSpace};
	align-items:center;
	&.center{
		justify-content:center;
	}
`;

export const DotsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
	margin-top:10px;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
	gap:0.5rem
`;

export const Dot = styled.button<{ selected?: boolean }>`
  -webkit-tap-highlight-color: rgba(49, 49, 49, 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: .725rem;
  height: .725rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &:after {
    // box-shadow: inset 0 0 0 0.2rem rgb(234, 234, 234);
    width: .725rem;
    height: .725rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    content: "";
		background-color: #eee;
  }

  ${({ selected }) =>
		selected &&
    `
    &:after {
		 	background-color: var(--secondary);
    }
  `}
`;

export {
	SlideViewport,
	Slide,
	SlideContainer,
	SlideSection
};
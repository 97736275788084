import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { detectPanelColor, resizePoints } from '../utils/util';
import { Line } from 'react-konva';
import {
	KONVA_FACET_GROUP,
	PANEL_OFF_COLOR,
	PANEL_OVERLAP_THRESHOLD,
	PANEL_STROKE_COLOR,
	SEMI_TRANPARENT_BLACK_PANEL,
	STROKE_COLOR_FOR_USER_MAPPED_PANEL
} from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { updateEnergyStateFromPanelClicks } from 'store/slices/EnergySlice/energySlice';
import { EnergyState } from 'store/slices/EnergySlice/types';
import { RootState } from 'store';
import { setIntermediateEnergyState, toggleOffsetState } from 'store/slices/OffsetModelSlice/offsetmodelSlice';
import { useRefs } from 'contexts/RefContext';
import { calculateOffsetPercentage, getDeratedValue } from '../../../util';
import { setCurrentMovablePanels, setDeletedPanels } from 'store/slices/PanelSlice';
import { KonvaEventObject } from 'konva/lib/Node';
import Konva from 'konva';
import { checkIntersection, getRotatedLinePoints, getSnappedPosition, isOverLap } from '../PanelUtil';
import { CurrentMovablePanel } from 'store/slices/ToolSlice/types';
import { getAbsolutePoints } from 'components/DisplayEnergy/util';
import { getExistingPanelsGroupID, getRoofSegmentIDFromGroupID, KonvaGroupNames } from '../utils';
import { useKonvaImageScale } from 'contexts/ToolDimensionsAndScaleContext';

type Props = {
	panelData: RasterSolarPanel & {userMappedFacet: boolean},
	panelId: string,
	invalidAzimuth: boolean,
};

function SolarPanel(props: Props) {
	const { panelData: panel, panelId, invalidAzimuth } = props;
	const dispatch = useDispatch();
	const { energy, billEnergy, selectedPanels, deratedBy } = useSelector((state: RootState) => state.energyData.data as unknown as EnergyState);
	const { userHasGivenPermToExceedOffset } = useSelector((state: RootState) => state.offsetModal.data);
	const { editModeEnabled, roofIndexes, userHasClickedSave, saveWithPanelColor, moveStage, enabledDevMode } 
	= useSelector((state: RootState) => state.toolNewPostions.data);
	const { enablePanelMove, deleteIndividualPanelMode } = useSelector((state: RootState) => state.panelSlice.data);
	const { org, allRoofSegs, enableEditFacet } = useSelector((state: RootState) => state.roofData.data);
	const shouldListen = !(moveStage || enableEditFacet);

	const { sliderRef, konvaRef } = useRefs();
	const scale = useKonvaImageScale();
	const devStrokeColor = 'rgb(3, 122, 241)';

	const panelIsInEditMode = editModeEnabled || roofIndexes.includes(panel.segmentId);
	const canSelectPanel = !panelIsInEditMode && !moveStage;

	const intersectedFacetRef = useRef<Konva.Line | null>(null);
	const panelRef = useRef<Konva.Line | null>(null);
 
	const [initialPoints, setInitialPoints]= useState<number[]>([]);
	const [color, setColor] = useState(() => {
		if (selectedPanels.includes(panel.id)) return detectPanelColor(panel.yearlyEnergyDcKwh);
		return PANEL_OFF_COLOR;
	});
	
	{
		let panelColor;
		if (panelIsInEditMode)
			panelColor = SEMI_TRANPARENT_BLACK_PANEL;
		else {
			if(userHasClickedSave && !saveWithPanelColor)
				panelColor = selectedPanels.includes(panel.id) ? SEMI_TRANPARENT_BLACK_PANEL : PANEL_OFF_COLOR;
			else
				panelColor = selectedPanels.includes(panel.id) ? detectPanelColor(panel.yearlyEnergyDcKwh) : PANEL_OFF_COLOR;
		}
			
		if (color !== panelColor) {
			setColor(panelColor);
		}
	}

	function updateOffsetModal() {
		let offsetUpdated = false;
		if (color === PANEL_OFF_COLOR) {
			const intermediiateOffset = calculateOffsetPercentage(getDeratedValue((energy + panel.yearlyEnergyDcKwh), deratedBy), billEnergy);
			dispatch(setIntermediateEnergyState({ value: { source: 'panel', data: { panelInfo: { panel, panelId } } } }));
			if (!userHasGivenPermToExceedOffset.offset120 && intermediiateOffset >= 120 && org.setting?.enableOffsetWarning) {
				dispatch(toggleOffsetState({ value: true }));
				offsetUpdated = true;
			}
			if (!userHasGivenPermToExceedOffset.offset150 && intermediiateOffset >= 150 && org.setting?.enableOffsetWarning) {
				dispatch(toggleOffsetState({ value: true }));
				offsetUpdated = true;
			}
		}
		return offsetUpdated;
	}

	function updateSLider(dir: string) {
		if (!sliderRef?.current) return;
		const step = +sliderRef.current.step;
		const sliderVal = +sliderRef.current.value;
		let rangeVl;
		if (dir === 'up') {
			sliderRef.current.value = (sliderVal + step).toString();
			rangeVl = ((sliderVal + step) / +sliderRef.current.max) * 100;
		}
		else {
			sliderRef.current.value = (sliderVal - step).toString();
			rangeVl = ((sliderVal - step) / +sliderRef.current.max) * 100;
		}
		sliderRef.current.style.background = `linear-gradient(to right, var(--secondary) 0%, var(--secondary) ${rangeVl}%, #c4c4c4 ${rangeVl}%, #c4c4c4 100%)`;
	}

	function updateColorAndMetada() {
		const energyBoost = color === PANEL_OFF_COLOR ? panel.yearlyEnergyDcKwh : -panel.yearlyEnergyDcKwh;
		const irradianceBoost = color === PANEL_OFF_COLOR ? panel.irradiance : -panel.irradiance;
		dispatch(updateEnergyStateFromPanelClicks({ energyBoost, panelId, irradianceBoost }));
		setColor((prevState) => (prevState === PANEL_OFF_COLOR ? detectPanelColor(panel.yearlyEnergyDcKwh) : PANEL_OFF_COLOR));
	}

	function handleClick() {
		if(deleteIndividualPanelMode){
			dispatch(setDeletedPanels({data: [{panelId, roofSegmentId: panel.segmentId }]}));
			return;
		}
		if (panelIsInEditMode) return;
		if (!canSelectPanel) return;
		if (!updateOffsetModal()) {
			updateColorAndMetada();
			updateSLider((color === PANEL_OFF_COLOR) ? 'up' : 'down');
		}
	}

	const panelPoints = useMemo(() => {
		return resizePoints(panel.exteriorCoords.flat(), scale);
	},[scale, panel.exteriorCoords]);

	useEffect(() => {
		if (!enablePanelMove && panelRef?.current && konvaRef?.current) {
			if (!panelRef.current?.attrs?.position) return;

			if (getRoofSegmentIDFromGroupID(panelRef.current?.getParent()?.id()) !== panel.segmentId) {
				const originPanelGroup = konvaRef?.current?.findOne(`#${getExistingPanelsGroupID(panel.segmentId)}`);
				panelRef.current.moveTo(originPanelGroup);
			}
			panelRef.current.setPosition({ x: 0, y: 0 });
			panelRef.current.attrs.position = undefined;
			panelRef.current.points(resizePoints(panel.exteriorCoords.flat(), scale));
		}
	}, [enablePanelMove, panelRef, panel, konvaRef, scale]);

	const strokeColor = useMemo(() => {
		return ((panel?.userMapped || panel.userMappedFacet) && editModeEnabled) ? STROKE_COLOR_FOR_USER_MAPPED_PANEL : PANEL_STROKE_COLOR;
	}, [editModeEnabled, panel?.userMapped]);

	const handlePointerDown = (e:any) => {
		const shape = e.target;
		if(panelRef.current)
			panelRef.current.attrs.position = shape.position();
		setInitialPoints(shape.points());

	};
	
	const handleDragMove = useCallback(function (e: KonvaEventObject<DragEvent>) {
		if (!konvaRef?.current) return;
		const shape = e.target as Konva.Line;
		konvaRef.current.container().style.cursor = 'move';
		const currentPos = shape.position();
		intersectedFacetRef.current = null;

		const allFacets = konvaRef.current.find(`.${KonvaGroupNames.existingFacet}`) as Konva.Line[];
		const intersectedFacet = checkIntersection(allFacets, shape);
		if (!intersectedFacet) return;

		intersectedFacetRef.current = intersectedFacet as Konva.Line;
		const parentGroup = intersectedFacetRef.current?.parent as Konva.Group | null;
		if(!parentGroup) return;
		const panelGroup = parentGroup?.findOne(`.${KonvaGroupNames.existingPanelsGroup}`) as Konva.Group | null;
		if(!panelGroup) return;
		shape.moveTo(panelGroup);

		const siblings = panelGroup?.children?.filter(c => c.id() !== shape.id()) as Konva.Line[];		
		const matchingRoofSeg = allRoofSegs.find(seg => seg.id === intersectedFacet.id());
		if (matchingRoofSeg) {
			const dims = {
				width: matchingRoofSeg.panelWidth * scale.x,
				height: matchingRoofSeg.panelHeight * Math.cos(matchingRoofSeg.pitchDegrees * (Math.PI / 180)) * scale.y
			};
			const width = dims.width, height = dims.height;
			let rotationAngle = matchingRoofSeg.azimuthDegrees;
		
			if (matchingRoofSeg.orientation !== panel.orientation) {
				rotationAngle += 90;
			}
			rotationAngle %= 360;

			const points = getRotatedLinePoints(e.target.getRelativePointerPosition(), width, height, rotationAngle);
			shape.points(points);

			if (!siblings?.length) return;
			const { hasOverlap } = isOverLap(shape, siblings as Konva.Line[], PANEL_OVERLAP_THRESHOLD);

			const newStrokeColor = hasOverlap ? 'red' : strokeColor;
			shape.stroke(newStrokeColor);
			
			const snappedPosition = getSnappedPosition(currentPos, shape, siblings);
			if (snappedPosition) shape.position(snappedPosition);
		}
	}, [konvaRef, allRoofSegs, scale.x, scale.y, panel.orientation, strokeColor]);

	function handleDragEnd(e: KonvaEventObject<DragEvent>) {
		if (!konvaRef?.current) return;
		const shape = e.target as Konva.Line;
		konvaRef.current.container().style.cursor = 'default';
		if (!panelRef?.current) return;
		//dont allow if overlap deteected/ or not facet detected move it back to original pos
		if (!intersectedFacetRef?.current || shape.attrs.stroke === 'red') {
			shape.points(initialPoints);
			shape.stroke(strokeColor);
			panelRef.current.attrs.position && shape.position(panelRef.current.attrs.position);
			const prevousSegmentID = shape.attrs.prevousSegmentID ?? panel.segmentId;
			const previousGroup = konvaRef.current.findOne(`#${prevousSegmentID}${KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR}${KonvaGroupNames.existingPanelsGroup}`);
			if (previousGroup && previousGroup.id() !== shape.parent?.id()) {
				shape.moveTo(previousGroup);
			}
			return;
		}

		const rs = allRoofSegs.find(r => r.id === intersectedFacetRef.current?.id());
		if (!rs) return;
		shape.attrs.prevousSegmentID = rs.id;
		const exteriorCoords: number[][] = [];
		const coords = getAbsolutePoints(shape, scale, konvaRef.current);
		for (let i = 0; i < coords.length; i += 2) {
			exteriorCoords.push([coords[i], coords[i + 1]]);
		}
		const shouldClosePolygon = exteriorCoords.length === 4; // since panels are rect;
		if (shouldClosePolygon) exteriorCoords.push(exteriorCoords[0]);
		const movedPanelCongif: CurrentMovablePanel = {
			orientation: panel.orientation,
			originSegmentId: panel.segmentId,
			exteriorCoords,
			panelId: shape.id(),
			roofSegmentId: rs.id
		};

		dispatch(setCurrentMovablePanels({ currentMovablePanel: movedPanelCongif }));
	}

	return (
		<Line
			ref= {panelRef}
			id={panel.id}
			stroke={invalidAzimuth ? '#FF0000' : (enabledDevMode && panel.userMapped ? devStrokeColor : strokeColor)}
			closed={true}
			strokeWidth={1.4}
			points={panelPoints}
			fill={color}
			onClick={handleClick}
			onTap={handleClick}
			draggable={enablePanelMove}
			onPointerDown={handlePointerDown}
			onDragMove={handleDragMove}
			onDragEnd={handleDragEnd}
			listening={shouldListen}
			name={`panel-${panel.segmentId}`}
		/>
	);
}

export default memo(SolarPanel);


import { useRefs } from 'contexts/RefContext';
import { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from '../util';
import useScreenDims from './useScreenDims';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { BREAK_POINT_FOR_MOBILE_VIEW, STANDARD_IMAGERY_SIZE } from '../constants';

const useKonvaStageDimensions = ({ stageWrapRef }: { stageWrapRef: MutableRefObject<HTMLDivElement | null> }): Dimensions => {
	const { konvaRef } = useRefs();
	const screenDims = useScreenDims();
	const { imgHeight, imgWidth, org } = useSelector((state: RootState) => state.roofData.data);
	const { fullScreenEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);

	const [stageDims, setStageDims] = useState<Dimensions>({ height: imgHeight, width: imgWidth });

	const setCanvasDimensionsBasedOnScreen = useCallback(() => {
		const stageWrap = stageWrapRef?.current;
		const stage = konvaRef?.current;

		if (!stageWrap || !stage) return;

		const imageRatio = imgWidth / imgHeight;
		const { width: screenWidth, height: screenHeight } = screenDims;
		const stretchImagery = org.setting.isB2C;
		const isDesktopView = screenWidth >= BREAK_POINT_FOR_MOBILE_VIEW;
		const isMobileView = 200 < screenWidth && screenWidth < 500;
		const rectTop = stageWrap.getBoundingClientRect().top;

		let width = STANDARD_IMAGERY_SIZE,
			height = STANDARD_IMAGERY_SIZE;

		if (stretchImagery) {
			if (isDesktopView){
				width = stageWrap.clientWidth;
				height = screenHeight - rectTop;
			} 
			if (isMobileView) {
				width = screenWidth;
				height = STANDARD_IMAGERY_SIZE;
			}
		} else {
			if (isDesktopView && stageWrap.clientWidth > STANDARD_IMAGERY_SIZE) {
				width = fullScreenEnabled ? screenWidth : stageWrap.clientWidth;
				height = fullScreenEnabled ? screenHeight - rectTop : width / imageRatio;
			}
		}
		stage.width(width);
		stage.height(height);
		setStageDims({ width, height });
	}, [fullScreenEnabled, imgHeight, imgWidth, konvaRef, org.setting.isB2C, screenDims, stageWrapRef]);

	const debouncedUpdateCanvasDimensions = useMemo(() => debounce(setCanvasDimensionsBasedOnScreen, 300),[setCanvasDimensionsBasedOnScreen]);

	useEffect(() => {
		debouncedUpdateCanvasDimensions();
	}, [debouncedUpdateCanvasDimensions]);

	return stageDims;
};

export default useKonvaStageDimensions;
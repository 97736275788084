import { SOlAR_QOUTE_APP_NAME } from '../constants';
import { createContext, ReactNode, useContext, useLayoutEffect, useReducer } from 'react';

type Theme = {
	colors: { [key: string]: string };
	runAiBtnText: string;
	selectHouseText: string;
	saveDesignBtnText: string;
	adjustPanelText: string;
	mapMarkerColor: string;
	nextSteps?: Array<{
		step: number;
		title: string;
		desc: string;
	}>
	showNextSteps: boolean;
	adjustSystemSizeText: string | null;
	mainHeading: string;
}

const defaultThemeColors: { [key: string]: string } = {
	primary: '#003cff',
	secondary: '#ffc005',
};

const defaultTheme = {
	colors: defaultThemeColors,
	runAiBtnText: 'Run AI Design',
	selectHouseText: 'Place the crosshairs over the center of the house in the map',
	saveDesignBtnText: 'Save',
	adjustPanelText: 'Adjust Panel Here',
	mapMarkerColor: 'black',
	showNextSteps: false,
	mainHeading: '',
	adjustSystemSizeText: null
};

const defaultB2CTheme = {
	...defaultTheme,
	selectHouseText: 'Select your home and put in the center of the map',
	saveDesignBtnText: 'Next',
	mapMarkerColor: 'red',
	mainHeading: 'Your Home Design'
};

type Action = { type: 'SET_THEME'; payload: { 
	settings: organization['settings'], 
	parentAppName: string; 
	config?: organization['configurations']
} };

function themeReducer(state: Theme, action: Action): Theme {
	if (action.type === 'SET_THEME') return configureTheme(state, action.payload);
	return state;

}

function configureTheme(state: Theme, payload: Action['payload']) {
	const { parentAppName, settings, config } = payload;
	const useB2CTheme = settings?.isB2C || parentAppName === SOlAR_QOUTE_APP_NAME;
	const defaultOrgTheme = useB2CTheme ? defaultB2CTheme : defaultTheme;

	return ({
		...defaultOrgTheme,
		colors: { ...state.colors, ...(config?.cssVariables || settings?.cssVariables || {})},
		runAiBtnText : config?.setHouseButtonText || settings?.dataForTool?.runAiBtnText || defaultOrgTheme.runAiBtnText,
		selectHouseText: config?.setHouseHelpText || settings?.dataForTool?.selectHouseText || defaultOrgTheme.selectHouseText,
		saveDesignBtnText: config?.saveDesignButtonText || settings?.dataForTool?.seeRecommendButtonText || defaultOrgTheme.saveDesignBtnText,
		adjustPanelText: config?.adjustPanelText || settings?.dataForTool?.adjustPanelText || defaultOrgTheme.adjustPanelText,
		adjustSystemSizeText: config?.adjustSystemSizeHelpText || settings?.dataForTool?.adjustSystemSizeText,
	});
}

const ThemeContext = createContext<Theme>(defaultTheme);
const ThemeDispatchContext = createContext<React.Dispatch<Action> | undefined>(undefined);

export function ThemeProvider({ children }: { children: ReactNode }) {
	const [state, dispatch] = useReducer(themeReducer, defaultTheme);

	useLayoutEffect(() => {
		Object.entries(state.colors).forEach(([key, value]) => {
			document.documentElement.style.setProperty(`--${key}`, value);
		});
	}, [state.colors]);

	return (<ThemeContext.Provider value={state}>
		<ThemeDispatchContext.Provider value={dispatch}>
			{children}
		</ThemeDispatchContext.Provider>
	</ThemeContext.Provider>);
}

export function useOrgTheme() {
	const context = useContext(ThemeContext);
	if (!context) {
		throw new Error('useOrgTheme should be used within a ThemeProvider');
	}
	return context;
}

export function useThemeDispatch() {
	const context = useContext(ThemeDispatchContext);
	if (!context) {
		throw new Error('useOrgTheme should be used within a ThemeProvider');
	}
	return context;
}

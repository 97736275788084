import styled, { Interpolation, keyframes } from 'styled-components';

type props = {
  styles?: Interpolation<React.CSSProperties>
}

const LoaderDiv = styled.div<props>`
display: flex;
flex-direction:column;
width: 100%;
height: 100%;
align-items: center;
justify-content: center;	
// min-height: 100vh;
img{margin-top: 5px;}

${({ styles }) => styles}
`;

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0.6;
  }
`;

const PulseLoaderDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px; /* Adds spacing between dots */
`;

const PulseDot = styled.div<{ delay: string }>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #3498db; /* Change as needed */
  animation: ${pulseAnimation} 0.6s infinite alternate;
  animation-delay: ${(props) => props.delay}; /* Dynamic delay */
`;

export {
	LoaderDiv,
	PulseDot,
	PulseLoaderDiv

};
import { useCallback } from 'react';
import { Group, Line } from 'react-konva';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { KonvaGroupNames, resizePoints } from '../utils';
import { KONVA_FACET_GROUP } from '../../../constants';
import { Context } from 'konva/lib/Context';
import Konva from 'konva';
import { useKonvaImageScale } from 'contexts/ToolDimensionsAndScaleContext';

export default function SetbackArea() {

	const { allRoofSegs } = useSelector((state: RootState) => state.roofData.data);
	const { deletedRoofIndexes, editModeEnabled, enabledDevMode } = useSelector((state: RootState) => state.toolNewPostions.data);
	const isIrradianceOn = useSelector((state: RootState) => state.Irradiance.data.showIrradiance);

	const scale = useKonvaImageScale();

	const sceneFunc = useCallback((context: Context, shape: Konva.Shape, rs: RasterRoofSegment) => {
		if (!rs.simplifiedHullCoords?.length || !rs.setbackPolygonCoords?.length) return;

		const isClockwise = (points: number[][]) => {
			let sum = 0;
			for (let i = 0; i < points.length; i++) {
				const [x1, y1] = points[i];
				const [x2, y2] = points[(i + 1) % points.length];

				sum += (x2 - x1) * (y2 + y1);
			}
			return sum > 0;
		};

		const isWindingClockWise = isClockwise(rs.simplifiedHullCoords);
		const simplifiedPolygonCoords = rs.simplifiedHullCoords.slice();

		if (isWindingClockWise) {
			simplifiedPolygonCoords.reverse();
		}

		const transformedSimplifiedFacetPolygon = resizePoints(simplifiedPolygonCoords?.flat(), scale);
		const transformedSetbackPolygon = resizePoints(rs?.setbackPolygonCoords.flat(), scale);

		const arePolygonsEqual = JSON.stringify(transformedSimplifiedFacetPolygon.flat().sort()) ===
			JSON.stringify(transformedSetbackPolygon.flat().sort());

		context.beginPath();
		context.moveTo(transformedSimplifiedFacetPolygon[0], transformedSimplifiedFacetPolygon[1]);
		for (let i = 2; i < transformedSimplifiedFacetPolygon.length; i = i + 2) {
			context.lineTo(transformedSimplifiedFacetPolygon[i], transformedSimplifiedFacetPolygon[i + 1]);
		}
		context.closePath();

		context.moveTo(transformedSetbackPolygon[0], transformedSetbackPolygon[1]);
		for (let i = 2; i < transformedSetbackPolygon.length; i = i + 2) {
			context.lineTo(transformedSetbackPolygon[i], transformedSetbackPolygon[i + 1]);
		}

		context.closePath();
		const shouldFill = !arePolygonsEqual;
		if (shouldFill) {
			context.fillStyle = 'orange';
			context.fill();
		}
		context.fillStrokeShape(shape);
	}, [scale]);
	const devStrokeColor = 'rgb(3, 122, 241)';


	return (
		<Group
			name={KonvaGroupNames.existingFacetsSetbackAreaGroup}
			listening={false}
			visible={editModeEnabled && !isIrradianceOn}
		>
			{
				allRoofSegs.map(rs => {
					if (deletedRoofIndexes.includes(rs.id)) return null;
					return (
						<Line
							id={`${rs.id}${KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR}${KonvaGroupNames.existingFacetsSetbackAreaGroup}`}
							key={rs.id}
							closed={true}
							stroke={enabledDevMode && rs.userMapped ? devStrokeColor : 'orange'}
							opacity={enabledDevMode && rs.userMapped ? 1 : 0.3}
							strokeWidth={2}
							sceneFunc={(con: Context, shape: Konva.Shape) => sceneFunc(con, shape, rs)}
						/>);
				})
			}

		</Group>
	);

}
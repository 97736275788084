import Button from 'components/Basic/Button';
import { useDispatch, useSelector } from 'react-redux';
import { redoPanelState, undoPanelState } from 'store/slices/PanelSlice';
import { RootState } from 'store';
import { redoEditableFacets } from 'store/slices/RoofDataSlice/roofDataSlice';

export default function UndoButton() {
	const dispatch = useDispatch();
	const { enableAddPanel, deleteIndividualPanelMode, currentIndex:panelHistoryCurrentIndex, panelHistory, newlyAddedPanels, deletedPanels } = useSelector((state: RootState) => state.panelSlice.data);
	const { enableEditFacet, currentIndex:facetHistoryCurrentIndex, facetHistory} = useSelector((state: RootState) => state.roofData.data);
	const panelMode = (deleteIndividualPanelMode || enableAddPanel) && !!(newlyAddedPanels?.length || deletedPanels?.length) || panelHistory.length > 0;
	const facetMode = enableEditFacet;
	const show = panelMode || facetMode;
	const shouldDisable = (panelMode && panelHistoryCurrentIndex >= panelHistory.length - 1) || (facetMode && facetHistoryCurrentIndex >= facetHistory.length - 1);
	
	function handleUndoClick() {
		if(panelMode) {
			dispatch(redoPanelState());
		}
		if(facetMode) {
			dispatch(redoEditableFacets());
		}
	}

	if (show) {
		return (
			<>
				<Button
					className='active move-redo'
					id="redo"
					style={{
						background: `${shouldDisable ? '#fff' : 'rgb(from #003CFF r g b / 10%)'}`,
					}}
					disabled={shouldDisable}
					onClick={handleUndoClick}
					// tooltipContent='Redo'
				>

					<svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M19 5.15385H7.92308C6.08696 5.15385 4.32605 5.88324 3.02772 7.18157C1.72939 8.4799 1 10.2408 1 12.0769C1 13.913 1.72939 15.6739 3.02772 
					16.9723C4.32605 18.2706 6.08696 19 7.92308 19H13.4615M19 5.15385L14.8462 1M19 5.15385L14.8462 9.30769" 
						stroke="#64686A"
						strokeWidth="1.6" 
						strokeLinecap="round" 
						strokeLinejoin="round"/>
					</svg>

				</Button>
			</>
		);
	}

	return null;

}